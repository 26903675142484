<template>
    <NavMenu :menu="menu" />
</template>

<script setup lang="ts">

import NavMenu from '../NavMenu.vue';

import {
  Menu,
  userHasPermission,
} from '@/internal';

import {computed} from "vue";

const menu = computed<Menu>(() => {
  return new Menu({
    title: 'Create',
    menuItems: [
      {
        icon: 'file-plus',
        route: {
          name: 'forms/create',
        },
        title: 'Form',
        when: userHasPermission('form:create'),
      },
      {
        icon: 'file-plus',
        route: {
          name: 'questions/create',
        },
        title: 'Form Question',
        when: userHasPermission('formQuestion:create'),
      },
    ],
  });
});

</script>
