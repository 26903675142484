export interface ColumnType {
  component?: any,
  editable?: boolean,
  field: string,
  formatter?: any,
  label: string,
  maxWidth?: number,
  numeric?: boolean,
  rank?: number,
  sortable?: boolean,
  truncate?: boolean,
  type?: string,
  visible?: boolean,
  width?: number,
};

export default class Column {
  component: any;
  editable: boolean;
  field: string;
  formatter: any;
  label: string;
  maxWidth: number | null;
  numeric: boolean;
  rank: number;
  sortable: boolean;
  truncate: boolean;
  type: string | null;
  visible: boolean;
  width: number | null;

  constructor(config: ColumnType) {
    this.component = config.component || null;
    this.editable = config.editable || false;
    this.field = config.field;
    this.formatter = config.formatter || null;
    this.label = config.label;
    this.maxWidth = config.maxWidth || null;
    this.numeric = config.numeric || false;
    this.rank = config.rank || 0;
    this.sortable = config.sortable || false;
    this.truncate = config.truncate || false;
    this.type = config.type || null;
    this.visible = config.visible || (this.field !== 'id' && (config.visible === undefined || config.visible));
    this.width = config.width || null;
  }
}