import type {APIResponse} from '@/api/Api';
import {Api} from '@/api/Api';
import {deserialize} from './Deserializer';
import type {SearchResult} from "@/models/SearchResult";
import {SearchResultSchema} from "@/models/SearchResult";

export default class SystemAPI extends Api {

  search(params: object): Promise<SearchResult> {
    return this.get({
      url: "system/search",
      queryParams: params
    })
    .then((res) => this.processResponse(res))
    .then(res => deserialize<SearchResult>(res, SearchResultSchema) as SearchResult);
  }

};