import { z } from "zod";

import {
    utcToLocal,
} from "@/internal";
import {TradeSchema} from "@/models/Trade";

export const ProjectBidderSchema = z.object({
    id: z.coerce.number(),
    project_id: z.coerce.number().optional().nullable(),
    bid_package_id: z.coerce.number().optional().nullable(),
    company_id: z.coerce.number().optional().nullable(),
    contact_id: z.coerce.number().optional().nullable(),
    division_id: z.coerce.number().optional().nullable(),
    trade_id: z.coerce.number().optional().nullable(),
    client_id: z.coerce.number().optional().nullable(),
    confirmed: z.coerce.number().default(0),
    bid: z.coerce.number().optional().nullable(),
    bid_received: z.coerce.boolean().optional().nullable(),
    bid_awarded: z.coerce.boolean().optional().nullable(),
    invited_by: z.string().optional().nullable(),
    invite_sent: z.coerce.boolean().optional().nullable(),
    self_invite: z.coerce.boolean().optional().nullable(),

    bid_received_at: z.string().transform((val:string) => utcToLocal(val)).nullable(),
    last_accessed_at: z.string().transform((val:string) => utcToLocal(val)).nullable(),
    invited_at: z.string().transform((val:string) => utcToLocal(val)).nullable(),

    trade: TradeSchema.optional().nullable().default(null),
});


export type ProjectBidder = z.infer<typeof ProjectBidderSchema>;