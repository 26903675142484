import { z } from "zod";

import {ContactSchema} from "@/models/Contact";
import {ClientSchema} from "@/models/Client";

export const CompanySchema = z.object({
    id: z.coerce.number(),
    name: z.string().optional(),
    address: z.string().optional().nullable(),
    address2: z.string().optional().nullable(),
    city: z.string().optional().nullable(),
    state: z.string().optional().nullable(),
    zip: z.string().optional().nullable(),
    country: z.string().optional().nullable(),
    prequal_url: z.string().optional().nullable(),

    client: ClientSchema.optional().default(ClientSchema.parse({})),
    primary_contact: ContactSchema.optional().default(ContactSchema.parse({})),
});


export type Company = z.infer<typeof CompanySchema>;