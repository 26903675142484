import {z} from "zod";
import {
    utcToLocal,
} from "@/internal";


export const ProjectRFISchema = z.object({
    id: z.coerce.number(),
    project_id: z.coerce.number(),
    bid_package_id: z.coerce.number().nullable(),
    company_id: z.coerce.number().nullable(),
    contact_id: z.coerce.number().nullable(),
    trade_id: z.coerce.number().nullable(),
    subject: z.string().optional(),
    body: z.string().optional().nullable(),
    status: z.coerce.number(),

    submitted_at: z.string().transform<string>(v => utcToLocal(v)).nullable(),
});

export type ProjectRFI = z.infer<typeof ProjectRFISchema>;