<template>
    <div id="menu-panel-wrapper">
        <div class="mask" :class="{ 'is-active': active }" />
        <div id="menu-panel" :class="{ 'is-active': active }">
            <div id="menu-panel-content" v-if="active" v-on-click-outside="close">
                <div id="close-button" @click="close">
                    <o-icon icon="arrow-left" />
                </div>
                <FavoritesMenuPanel v-if="activeMenuPanel === 'favorites'" :recents="recentRoutes" />
                <AccountMenuPanel v-if="activeMenuPanel === 'account'" />
                <AddMenuPanel v-else-if="activeMenuPanel === 'add'" />
                <HelpMenuPanel v-else-if="activeMenuPanel === 'help'" @close="close" />
                <SearchMenuPanel v-else-if="activeMenuPanel === 'search'" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">

import { vOnClickOutside } from '~/@vueuse/components';

import AccountMenuPanel from './MenuPanels/AccountMenuPanel.vue';
import AddMenuPanel from './MenuPanels/AddMenuPanel.vue';
import FavoritesMenuPanel from './MenuPanels/FavoritesMenuPanel.vue';
import HelpMenuPanel from './MenuPanels/HelpMenuPanel.vue';
import SearchMenuPanel from './MenuPanels/SearchMenuPanel.vue';

import {ref, computed, watch} from "vue";
import { useRoute } from "vue-router";
import {storeToRefs} from "pinia";
import {isArray} from "lodash";
import {useProjectStore} from "@/stores/ProjectStore";
import {useSystemStore} from "@/stores/SystemStore";

const currentRoute = useRoute();
const systemStore = useSystemStore();
const recentRoutes = ref<Record<any, any>[]>([]);
const { project } = storeToRefs(useProjectStore());

const active = computed<boolean>(() => systemStore.navPanelOpen);

const activeMenuPanel = computed<string>(() => systemStore.navPanel);

const currentRouteName = computed(() => currentRoute.name);

function close() {
  systemStore.toggleNavPanel('');
}

function saveRoute(newRoute: Record<any, any>) {
  // Keep track of the last 10 page views
  const prevRoutes: Record<any, any>[] = [...recentRoutes.value.filter((route:  Record<any, any>) => route.path !== currentRoute.path)];
  prevRoutes.unshift(newRoute);
  recentRoutes.value = prevRoutes.slice(0, 10);
}

watch(currentRouteName, () => {

  const newRoute: Record<any, any> = {
    ...currentRoute,
  };

  if (currentRoute.meta?.useModelForTitle) {

    if (currentRoute.meta.useModelForTitle === 'project') {
      const projectId = parseInt(isArray(currentRoute?.params?.projectId) ? currentRoute.params.projectId[0] : currentRoute.params.projectId);
      const interval = setInterval(() => {
        if (project.value && projectId === project.value.id) {
          newRoute.title = project.value.name;
          saveRoute(newRoute);
          clearInterval(interval);
        }
      }, 1000);
      setTimeout(() => clearInterval(interval), 5000);
    }
  }
  else {
    saveRoute(newRoute);
  }

  close();
});

</script>

<style scoped lang="scss">

    #menu-panel-wrapper {
        bottom: 0;
        left: 0;
        position: fixed;
        top: 0;
        z-index: 3000;
    }

    #menu-panel {
        background: $grey-lightest;
        bottom: 0;
        display: flex;
        left: 0;
        padding: 15px;
        position: fixed;
        top: 0;
        transition: all .2s;
        transform: translateX(-100%);
        width: 320px;

        @include mobile {
            width: calc(100% - 50px);
        }

        &.is-active {
            transform: translateX(0);
        }

        #menu-panel-content {
            flex: 1;
            overflow: auto;
        }

        #close-button {
            border-radius: 50%;
            display: inline-block;
            padding: 5px;

            &:hover {
                background: $grey-lightest;
            }

            .icon {
                align-items: center;
                display: flex;
                height: 24px;
                justify-content: center;
                position: relative;
                top: 1px;
                width: 24px;
            }
        }
    }
</style>