import Menu from '@/use/Menu';
import {
  findRouteByName,
  userHasPermission,
} from '@/internal';

interface Payload {
  handleSystemPreferencesClick: Function,
}

export default (payload: Payload = { handleSystemPreferencesClick: () => {}}) => {

  const {
    handleSystemPreferencesClick,
  } = payload;

  return new Menu({
    title: 'Settings',
    menuItems: [
      // {
      //   route: findRouteByName('account/profile'),
      //   title: user.account.name,
      //   when: userHasPermission('client:read'),
      // },
      {
        onClick: handleSystemPreferencesClick,
        title: 'System Preferences',
        icon: 'cog',
        when: userHasPermission('client:update'),
      },
    ],
  });
};