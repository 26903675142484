import { z } from "zod";

import {ProjectSchema} from "@/models/Project";

export const SearchResultSchema = z.object({
    id: z.string(),
    projects: z.array(ProjectSchema).optional().default([]),
});


export type SearchResult = z.infer<typeof SearchResultSchema>;