import {defineStore} from "pinia";
import {StorageSerializers, useStorage} from "@vueuse/core";
import {ref} from "vue";
import BidPackageFileAPI from "@/api/BidPackageFileAPI";
import type {OPRFile} from "@/models/OPRFile";

export const useBidPackageFileStore = defineStore('bidPackageFiles', () => {

  const bidPackageFiles = useStorage('bidPackageFiles', [], sessionStorage, {
    serializer: StorageSerializers.object,
  });
  const isLoading = ref<boolean>(false);

  const clear = () => {
    bidPackageFiles.value = null;
  };

  const index = async (projectId: number, bidPackageId: number, payload: any) => {
    const { params } = payload;

    isLoading.value = true;
    clear();
    await new BidPackageFileAPI().index(projectId, bidPackageId, params)
      .then((data: OPRFile[]) => {
        bidPackageFiles.value = data;
      })
      .finally(() => isLoading.value = false);
  }

  const zip = async (projectId: number, bidPackageId: number, payload: any) => {
    isLoading.value = true;
    await new BidPackageFileAPI().zip(projectId, bidPackageId, payload)
      .finally(() => isLoading.value = false);
  }

  return {
    bidPackageFiles,
    isLoading,
    index,
    zip,
  };
});
