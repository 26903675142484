import {
  findRouteByName,
  Menu,
} from '@/internal';

interface Payload {
}

export default (payload: Payload = {}) => {

  const {  } = payload;

  return new Menu({
    menuItems: [
    ],
  });
};