import { z } from "zod";

export const ContactSchema = z.object({
    id: z.coerce.number().optional(),
    first_name: z.string().optional().nullable(),
    last_name: z.string().optional().nullable(),
    phone_number: z.string().optional().nullable(),
    extension: z.string().optional().nullable(),
    fax_number: z.string().optional().nullable(),
    cell_number: z.string().optional().nullable(),
    email: z.string().optional().nullable(),
    label: z.string().optional().nullable(),
    company_name: z.string().optional().nullable(),
});

export type Contact = z.infer<typeof ContactSchema>;