<template>
    <div class="bid-packages" :class="{'landing': layoutType === 'landing'}" v-if="projectId && project && ((bidPackages.length > 1) || (bidPackages.length && bidders.length))">
      <div class="label" :class="{'has-margin-left': layoutType !== 'landing'}">Invited Bid Packages</div>
      <div class="bid-packages-list" v-if="bidders.length || bidPackages.length">
        <template v-if="layoutType === 'landing'">
          <div class="has-margin-bottom-large">Select a Bid Package to view and correspond</div>
          <div class="box" v-if="bidders.length" @click="handleSelected('project')">
            <div>
              <div class="label">{{project.name}}</div>
              <div class="is-flex">
                <div class="label  has-margin-right">Bid Due:</div>
                <div>{{ project.sub_bid_due }}</div>
              </div>
              <div class="is-flex">
                <div class="label  has-margin-right">Bidding:</div>
                <div>{{ isBidding(project) }}</div>
              </div>
              <div class="is-flex">
                <div class="label  has-margin-right">Bid Uploaded:</div>
                <div>{{ isBidUploaded(project) }}</div>
              </div>
            </div>
          </div>
          <div class="box" v-for="bidPackage in bidPackages" @click="handleSelected(bidPackage.id)">
            <div>
              <div class="label">{{bidPackage.name}}</div>
              <div class="is-flex">
                <div class="label  has-margin-right">Bid Due:</div>
                <div>{{ bidPackage.sub_bid_due }}</div>
              </div>
              <div class="is-flex">
                <div class="label  has-margin-right">Bidding:</div>
                <div>{{ isBidding(bidPackage) }}</div>
              </div>
              <div class="is-flex">
                <div class="label  has-margin-right">Bid Uploaded:</div>
                <div>{{ isBidUploaded(bidPackage) }}</div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <o-menu class="menu">
            <o-menu-item :label="project.name"
                         class="menu-item"
                         :class="{'active': isActive(0)}"
                         @click="handleSelected('project')"
                         v-if="bidders.length"
            />
            <o-menu-item v-for="bidPackage in bidPackages"
                         :label="bidPackage.name"
                         class="menu-item"
                         :class="{'active': isActive(bidPackage.id)}"
                         @click="handleSelected(bidPackage.id)"
            />
          </o-menu>
        </template>
      </div>
    </div>
</template>

<script setup lang="ts">

import {useRoute, useRouter} from "vue-router";
import { storeToRefs } from 'pinia';

import {computed, onMounted, ref, toRefs, watch} from "vue";
import type {BidPackage} from "@/models/BidPackage";
import type {Project} from "@/models/Project";
import {useProjectStore} from "@/stores/ProjectStore";

const projectStore = useProjectStore();
const route = useRoute();
const activePackage = ref<number | string | null>(null);
const { project } = storeToRefs(useProjectStore());

type LayoutTypes = "menu" | "landing";

const props = withDefaults(defineProps<{
  layoutType?: LayoutTypes,
}>(), {
  layoutType: "menu",
});
const {
  layoutType,
} = toRefs(props);

const bidders = computed(() => project.value?.bidders || []);

const bidPackages = computed(() => project.value?.bid_packages || []);

const projectId = computed(() => parseInt(route.params.projectId as string));

const useBidPackageId = computed<number>(() => activePackage.value === 'project' ? 0 : activePackage.value);

function isActive(value: number | string | null) {
  return projectStore.viewBidPackageId === value;
}

function isBidding(obj: BidPackage | Project) {
  return obj.is_bidding === 1 ? "Yes" : (obj.is_bidding === 2 ? 'No' : (obj.is_bidding === 3 ? 'Unsure' : 'Unconfirmed'));
}

function isBidUploaded(obj: BidPackage | Project) {
  return obj.is_bid_uploaded ? 'Yes' : 'No';
}

function handleSelected(value: number | string | null) {
  activePackage.value = value;

}

watch(activePackage, () => {
  projectStore.setViewBidPackageId(useBidPackageId.value);
});

watch(project, () => {
  if (!bidders.value.length && bidPackages.value.length === 1) {
    activePackage.value = bidPackages.value[0].id;
  }
  else if (bidders.value.length && !bidPackages.value.length) {
    activePackage.value = 'project';
  }
  else {
    activePackage.value = null;
  }
});

</script>

<style lang="scss" scoped>

.bid-packages {
  &.landing {
    width: unset !important;
  }

  .bid-packages-list {

    .box {
      &:hover {
        border-color: $primary;
      }

      &:hover {
        cursor: pointer;
      }

      .column {
        white-space: nowrap;
      }
    }
  }
}

</style>

<style lang="scss">

.bid-packages {

  .bid-packages-list {
    .menu {
      margin-left: .75em;
      margin-right: .75em;

      li {
        list-style-type: none;
      }

      .menu-item {
        margin-top: 3px;
        margin-bottom: 3px;
        padding: .25em .75em;
        display: flex;
        color: unset;

        &.active {
          font-weight: 600;
        }
      }

    }
  }
}

</style>