import type {APIResponse} from '@/api/Api';
import {Api} from '@/api/Api';
import {deserialize} from './Deserializer';
import type {CompanyUpdate} from "@/models/CompanyUpdate";
import {CompanyUpdateSchema} from "@/models/CompanyUpdate";

export default class CompanyAPI extends Api {

  index(params: any) {
    return this.get({
      url: "company-updates",
      queryParams: params,
    })
    .then((res) => this.processResponse(res))
    .then(res => deserialize<CompanyUpdate>(res, CompanyUpdateSchema) as CompanyUpdate);
  }

  destroy(id: number) {
    return this.delete({
      url: `company-updates/${id}`,
      body: '',
    })
    .then((res) => this.processResponse(res))
  }

};