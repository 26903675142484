<template>
  <div class="file-column">
    <o-tooltip :label="value" multiline v-if="value">
      <div class="link is-link truncate" @click="handleShow">{{value}}</div>
    </o-tooltip>
  </div>
</template>

<script setup lang="ts">

import {computed, toRefs} from "vue";
import type {OPRFile} from "@/models/OPRFile";

const props = withDefaults(defineProps<{
  row: OPRFile,
  value: string
}>(), {
});
const {
  row,
  value,
} = toRefs(props);

function handleShow() {
  if (row.value.file_path) {
    window.open(row.value.file_path,'_blank');
  }
}

</script>

<style scoped lang="scss">
    .file-column {
      max-width: 200px;

      .link {
        max-width: 200px;
      }
    }
</style>

<style lang="scss">
    .file-column {

    }
</style>