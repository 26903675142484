import type {APIResponse} from '@/api/Api';
import {Api} from '@/api/Api';

export default class InviteAPI extends Api {

  index(projectId: number, params: any): Promise<APIResponse> {
    return this.get({
      url: `projects/${projectId}/invites`,
      queryParams: params
    })
    .then((res) => this.processResponse(res))
  }
};
