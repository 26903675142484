<template>

    <header class="header">

        <nav class="navbar" role="navigation" aria-label="main navigation">

            <div class="navbar-brand">

                <div class="navbar-item">
                  <img class="logo" :src="clientLogoURL" :alt="clientName" v-if="clientLogoURL">
                  <div class="label has-margin-left-large">{{clientName}}</div>
                </div>

                <a
                        role="button"
                        class="navbar-burger burger"
                        aria-label="menu"
                        aria-expanded="false"
                        :class="{ 'is-active': navBarOpen }"
                        @click="toggleNavBar"
                >
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>

            </div>

            <div class="navbar-menu" :class="{ 'is-active' : navBarOpen }">
                <div class="navbar-end"></div>
            </div>

        </nav>

    </header>

</template>

<script setup lang="ts">

  import { ref, computed } from "vue";
  import {storeToRefs} from "pinia";
  import {usePublicProjectStore} from "@/stores/PublicProjectStore";

  const { project } = storeToRefs(usePublicProjectStore());
  let navBarOpen = ref(false);

  const clientLogoURL = computed<string | null>(() => project.value?.client?.logo_url ? project.value.client.logo_url : null);

  const clientName = computed(() => project.value?.client?.name ? project.value.client.name : 'SubsPortal');

  const toggleNavBar = () => {
    navBarOpen.value = !navBarOpen.value;
  };

</script>

<style lang="scss" scoped>
    header.header {
        font-size: 16px;

        .navbar-item {
          height: 5.25rem;
        }

        .logo {
            max-height: 5.25rem;
        }
    }
</style>