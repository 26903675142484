import {defineStore} from "pinia";
import CompanyAPI from "@/api/CompanyAPI";
import {type RemovableRef, StorageSerializers, useStorage} from "@vueuse/core";
import {ref} from "vue";
import type {Company} from "@/models/Company";

export const useCompanyStore = defineStore('company', () => {

  const company: RemovableRef<Company | null> = useStorage('company', null, sessionStorage, {
    serializer: StorageSerializers.object,
  });
  const isLoading = ref<boolean>(false);

  const clear = () => {
    company.value = null;
  };

  const getCompany = async (payload: any = { params: null}) => {
    const { params } = payload;

    isLoading.value = true;
    await new CompanyAPI().index(params)
      .then(data => {
        company.value = data;
      })
      .finally(() => isLoading.value = false);
  };

  const update = async (payload: any = { params: null }) => {
    const { params, ...rest } = payload;

    isLoading.value = true;
    return await new CompanyAPI().update(rest)
        .then(data => {
          return data;
        })
        .finally(() => isLoading.value = false);
  };

  return {
    company,
    isLoading,
    getCompany,
    update,
  };
});
