import {defineStore} from "pinia";
import ProjectAPI from "@/api/ProjectAPI";
import { StorageSerializers, useStorage } from '@vueuse/core';
import {ref} from "vue";
import type {Project} from "@/models/Project";

export const usePublicProjectStore = defineStore('public-project', () => {
    const isLoading = ref<boolean>(false);

    const project = useStorage<Project | undefined>('public-project', null, sessionStorage, {
        serializer: StorageSerializers.object,
    });

    const clear = () => {
        project.value = null;
    };

    const show = async (projectId: number, payload: any = {params: null}) => {
        isLoading.value = true;
        clear();

        const { params } = payload;
        await new ProjectAPI().showPublic(projectId, params)
            .then(data => {
                project.value = data;
            })
            .finally(() => isLoading.value = false);
    }

    return {
        project,
        isLoading,
        show,
    };
});
