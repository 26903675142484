import Index from "@/pages/Projects/Index.vue";
import Past from "@/pages/Projects/Past.vue";
import Show from "@/pages/Projects/Show.vue";

export default [
  {
    name: 'projects',
    path: '/projects',
    component: Index,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
      ],
      icon: 'inboxes',
      title: 'Current Projects',
    },
  },
  {
    name: 'projects/show',
    path: '/projects/:projectId/show',
    component: Show,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        'projects/current',
        'projects/show',
      ],
      icon: 'inbox-in',
      useModelForTitle: 'project',
    },
  },
  {
    name: 'projects/current',
    path: '/projects',
    component: Index,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
      ],
      icon: 'inbox-in',
      title: 'Current Projects',
    },
  },
  {
    name: 'projects/past',
    path: '/projects/past',
    component: Past,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
      ],
      icon: 'inbox-out',
      title: 'Past Projects',
    },
  },
  {
    name: 'projects/past/show',
    path: '/projects/past/:projectId/show',
    component: Show,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        'projects/past',
        'projects/past/show',
      ],
      icon: 'inbox-out',
      useModelForTitle: 'project',
    },
  },
]
