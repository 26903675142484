<template>
  <div id="submit-bid-tab" class="tab is-full-height">
    <o-loading
        :full-page="true"
        :active="bidPackageCompanyFileStore.isLoading"
        icon="rotate"
        icon-size="large"
    />

    <template v-if="hasInvites">
      <div class="label">Bid Forms</div>

      <div class="box overflow-scroll">
        <div class="has-margin-bottom">Please click the below link{{ invites.length > 1 ? 's' : '' }} and fill out our Bid Form{{ invites.length > 1 ? 's' : '' }}</div>
        <ul>
          <li v-for="invite in invites">
            <a class="is-link" :href="invite.url" target="_blank">{{ invite.name }}</a>
          </li>
        </ul>
      </div>
    </template>

    <template v-if="showUpload">
      <div class="label">Submit Bid</div>

      <div class="box overflow-scroll">

        <o-field :label="`Bid for Trade${tradeRequired ? '*' : ''}`"
                 addons-class="addons-class"
                 :variant="error('trade_id') ? 'danger' : ''"
                 :message="error('trade_id')"
                 v-if="bidders?.length"
        >
          <o-dropdown v-model="trade"
                      aria-role="list"
                      menu-class="menu-class"
                      :required="tradeRequired"
          >
            <template #trigger="{ active }">
              <div class="button is-secondary is-outlined">
                <span>{{ (trade?.full_label) || '--- Select ---'}}</span>
                <o-icon :icon="active ? 'caret-up' : 'caret-down'" pack="fas"></o-icon>
              </div>
            </template>

            <o-dropdown-item :value="null" v-if="((!tradeRequired && trade) || bidders.length > 1) && hasNonTradeOption">--- Select ---</o-dropdown-item>
            <o-dropdown-item :value="bidder.trade" v-for="bidder in bidders">{{ bidder?.trade?.full_label }}</o-dropdown-item>
          </o-dropdown>
        </o-field>

        <o-field label="File to Upload*"
                 :variant="error('question') ? 'danger' : ''"
                 :message="error('question')"
        >
          <FileUploadInput
              :params="storeParams"
              v-model:data="files"
              :multiple="true"
              @input="handleFileInput"
              @delete="handleFileDelete"
          />
        </o-field>

        <o-field label="Comment"
                 :variant="error('comment') ? 'danger' : ''"
                 :message="error('comment')"
        >
          <o-input v-model="comment" type="textarea"></o-input>
        </o-field>

        <div class="has-text-right has-margin-top-large">
          <o-button class="is-primary has-margin-right" @click="handleCancel">Cancel</o-button>
          <o-button class="is-primary" @click="handleSubmit" :disabled="disableSubmitButton">Submit Bid</o-button>
        </div>
      </div>

      <div class="label">Submitted Bids</div>
      <div class="box overflow-scroll">
        <CompanyFiles :bid-package-id="bidPackageId" />
      </div>
    </template>

  </div>
</template>

<script setup lang="ts">

import {
  NotificationError,
} from "@/internal";
import {computed, ref, toRefs, watch} from "vue";
import {storeToRefs} from "pinia";
import type {ProjectBidder} from "@/models/ProjectBidder";
import {number} from "zod";
import type {Trade} from "@/models/Trade";
import {useInviteStore} from "@/stores/InviteStore";
import CompanyFiles from "@/pages/Projects/CompanyFiles.vue";
import FileUploadInput from "@/components/Pickers/FileUploadInput.vue";
import {useBidPackageCompanyFileStore} from "@/stores/BidPackageCompanyFileStore";
import type {OPRFile} from "@/models/OPRFile";
import {useCompanyStore} from "@/stores/CompanyStore";
import {useBidPackageProjectBidderStore} from "@/stores/BidPackageProjectBidderStore";
import {useProjectStore} from "@/stores/ProjectStore";

const { bidPackageProjectBidders } = storeToRefs(useBidPackageProjectBidderStore());
const bidPackageCompanyFileStore = useBidPackageCompanyFileStore();
const { company } = storeToRefs(useCompanyStore());
const { invites } = storeToRefs(useInviteStore());
const { project } = storeToRefs(useProjectStore());
const comment = ref<String>('');
const defaultTrade = ref<Trade | null>(null);
const errors = ref(<Record<any, any>>[]);
const files = ref<any[]>([]);
const trade = ref<Trade | null>(null);
const tradeRequired = ref<boolean>(true);

const props = withDefaults(defineProps<{
  active: boolean,
  bidPackageId: number | null,
}>(), {
  active: false,
  bidPackageId: null,
});
const {
  active,
  bidPackageId,
} = toRefs(props);

const emit = defineEmits<{
  'updated': [],
}>();

const bidders = computed<ProjectBidder[]>(() => {
  const trades: number[] = [];
  return bidPackageProjectBidders.value?.filter((bidder: ProjectBidder) => {
    if ((bidder.trade && trades.includes(bidder.trade.id)) || !bidder.trade) {
      if (!bidder.trade) {
        tradeRequired.value = false;
      }
      return false;
    }
    else {
      trades.push(bidder.trade.id);
      return true;
    }
  });
});

const disableSubmitButton = computed(() => !files.value || !files.value.length || !project.value || bidPackageId.value === null || (tradeRequired.value && !trade.value));

const hasInvites = computed(() => !!invites.value?.length);

const hasNonTradeOption = computed(() => bidders.value.length !== bidPackageProjectBidders.value.length);

const showUpload = computed(() => !(company.value?.client.bid_forms_hide_upload && hasInvites.value));

const storeParams = computed(() => {
  return {
    project_id: project.value?.id,
    bid_package_id: bidPackageId.value || null,
    trade_id: trade.value?.id || null,
  };
});

function clearForm() {
  trade.value = defaultTrade.value;
  files.value = [];
  comment.value = '';
}

function clearErrors() {
  errors.value = [];
}

function error(key: string) {
  return errors.value?.find((error: any) => error.hasOwnProperty('key') && error.key === key)?.detail || '';
}

function handleCancel() {
  clearForm();
}

function handleFileDelete(uploadedFiles: OPRFile[] | File[]) {
  files.value = uploadedFiles;
}

function handleFileInput(uploadedFiles: File[]) {
  if (uploadedFiles.length) {
    files.value = files.value.concat(uploadedFiles);
  }
}

function handleSubmit() {

  if (!files.value || !files.value.length || !project.value || bidPackageId.value === null) {
    return;
  }

  const payload: any = {
    description: comment.value,
    trade_id: trade.value ? trade.value.id : null,
    files: files.value,
    client_id: project.value.client_id,
    business_unit_id: project.value.business_unit_id,
  };

  bidPackageCompanyFileStore.store(project.value.id, bidPackageId.value, payload)
    .then((response: any) => {
      clearForm();
    })
    .catch((err: any) => {
      NotificationError({
        message: 'Server Error',
      });
      console.error(err);
    })
    .finally(() => {
      emit('updated');
    });
}

watch(active, () => {
  if (active.value && !hasNonTradeOption.value && tradeRequired && bidders.value.length === 1) {
    trade.value = bidders.value[0].trade;
  }
  else {
    trade.value = null;
  }
  defaultTrade.value = trade.value;
});

</script>

<style lang="scss" scoped>

#submit-bid-tab {

  .box {
    &.overflow-scroll {
      overflow-x: auto;
    }
  }

  a {
    &.is-link {
      border: 0 !important;
    }
  }

  ul {
    border: 0 !important;
  }
}

</style>

<style lang="scss">

#submit-bid-tab {

  .tabs {
    a {
      &.is-link {
        border: 0;
      }
    }
  }

  .dropdown {
    a {
      &.dropdown-item {
        justify-content: left;

        &.is-active {
          color: $white !important;
        }
      }
    }
  }

}

</style>