import {z} from "zod";
import {
    utcToLocal,
} from "@/internal";

export const NoticeSchema = z.object({
    id: z.coerce.number(),
    title: z.string().optional(),
    scope: z.string().optional().nullable(),
    created_by: z.string().optional().nullable(),

    created_at: z.string().transform<string>(v => utcToLocal(v)).nullable(),
});

export type Notice = z.infer<typeof NoticeSchema>;