import Menu from '@/use/Menu';

interface Payload {
  hideTitles: boolean,
};

export default (payload: Payload) => {

  const {
    hideTitles,
  } = payload;

  const hideTitle: boolean = hideTitles !== undefined ? hideTitles : true;

  return new Menu({
    menuItems: [
      {
        hideTitle,
        route: {
          name: 'projects',
        },
        title: 'Powered by PreconSuite',
        usePsIcon: true,
      },
    ],
  });
}