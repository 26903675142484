import Menu from '@/use/Menu';

interface Payload {
  handleFavoritesClick: Function,
  handleSearchClick: Function,
  handleAddClick: Function,
  hideTitles: boolean,
};

export default (payload: Payload) => {

  const {
    handleFavoritesClick,
    handleSearchClick,
    handleAddClick,
    hideTitles,
  } = payload;

  const hideTitle: boolean = hideTitles !== undefined ? hideTitles : true;

  return new Menu({
    menuItems: [
      {
        hideTitle,
        route: {
          name: 'projects',
        },
        title: 'Current Projects',
        usePsIcon: true,
      },
      {
        hideTitle,
        icon: 'star',
        onClick: handleFavoritesClick,
        title: 'Recents',
      },
      {
        hideTitle,
        icon: 'search',
        onClick: handleSearchClick,
        title: 'Search',
      },
      // {
      //   hideTitle,
      //   icon: 'plus',
      //   onClick: handleAddClick,
      //   title: 'Add',
      // },
    ],
  });
}