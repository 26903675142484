import { DateTime } from "luxon";
import PKCE from "js-pkce";
export default class Auth {
  static #oauthClient() {
    return new PKCE({
      client_id: import.meta.env.VITE_APP_CLIENT_ID,
      redirect_uri: import.meta.env.VITE_APP_REDIRECT_URI,
      authorization_endpoint: import.meta.env.VITE_APP_AUTH_URI,
      token_endpoint: import.meta.env.VITE_APP_ACCESS_TOKEN_URI,
      requested_scopes: (import.meta.env.VITE_APP_SCOPES || "").split(","),
    });
  }

  static isAuthenticated() {
    const expiry = parseInt(window.sessionStorage.getItem("expiry") || '0');
    return DateTime.fromSeconds(expiry) > DateTime.now();
  }

  static authenticate() {
    window.sessionStorage.setItem('redirectPath', window.location.href);

    const oauth = this.#oauthClient();
    window.location.replace(oauth.authorizeUrl());
  }

  static getToken() {
    const oauth = this.#oauthClient();
    const url = window.location.href;
    return oauth.exchangeForAccessToken(url);
  }

  static setTokens(tokens: any) {
    window.sessionStorage.setItem("access_token", tokens.access_token);
    window.sessionStorage.setItem("refresh_token", tokens.refresh_token);
    window.sessionStorage.setItem("expiry", (DateTime.now().toUnixInteger() + tokens.expires_in).toString());
  }

  static refreshToken() {

  }

  static logout() {
    window.sessionStorage.removeItem("access_token");
    window.sessionStorage.removeItem("refresh_token");
    window.sessionStorage.removeItem("expiry");

    window.location.replace(import.meta.env.VITE_APP_LOGOUT_URI);
  }

}
