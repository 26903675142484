import type {APIResponse} from '@/api/Api';
import {Api} from '@/api/Api';
import {deserialize} from './Deserializer';
import type {Company} from "@/models/Company";
import {CompanySchema} from "@/models/Company";

export default class CompanyAPI extends Api {

  index(params: any) {
    return this.get({
      url: "companies",
      queryParams: params,
    })
    .then((res) => this.processResponse(res))
    .then(res => deserialize<Company>(res, CompanySchema) as Company);
  }

  update(data: any) {
    return this.put({
      url: `companies`,
      headers: [],
      body: JSON.stringify(data),
    })
    .then((res) => this.processResponse(res))
    .then(res => deserialize<Company>(res, CompanySchema) as Company);
  }

};