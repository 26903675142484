import {defineStore} from "pinia";
import BidPackageAPI from "@/api/BidPackageAPI";
import { StorageSerializers, useStorage } from '@vueuse/core';
import {ref} from "vue";
import type {BidPackage} from "@/models/BidPackage";

export const useBidPackageStore = defineStore('bidPackage', () => {

  const bidPackage = useStorage<BidPackage | undefined>('bidPackage', null, sessionStorage, {
    serializer: StorageSerializers.object,
  });
  const isLoading = ref<boolean>(false);

  const clear = () => {
    bidPackage.value = null;
  };

  const show = async (id: number, projectId: number, payload: any = {params: null}) => {
    isLoading.value = true;
    const { params } = payload;
    await new BidPackageAPI().show(id, projectId, params)
        .then(data => {
          bidPackage.value = data;
        })
        .finally(() => isLoading.value = false);
  }

  return {
    bidPackage,
    clear,
    isLoading,
    show,
  };
});
