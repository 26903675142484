<template>
  <div id="contacts-tab" class="tab is-full-height">

    <div class="label">Estimators</div>
    <div class="box">
      <IndexTable
          :columns="defaultEstimatorTableColumns"
          default-status-filter="active"
          :display-status-filter="false"
          :items="estimators"
          resource="user"
          title="Estimators"
          :display-actions="false"
      />
    </div>

    <div class="label">Contacts</div>
    <div class="box">
      <IndexTable
          :columns="defaultContactTableColumns"
          default-status-filter="active"
          :display-status-filter="false"
          :items="contacts"
          resource="project-contact"
          title="Contacts"
          :display-actions="false"
      />
    </div>

  </div>
</template>

<script setup lang="ts">

import {computed, ref, toRefs} from "vue";
import {storeToRefs} from "pinia";
import HTMLText from "@/components/HTMLText.vue";
import IndexTable from "@/components/IndexTable.vue";
import type {Notice} from "@/models/Notice";
import {usePublicProjectStore} from "@/stores/PublicProjectStore";
import {useProjectStore} from "@/stores/ProjectStore";
import {useBidPackageStore} from "@/stores/BidPackageStore";

const projectStore = useProjectStore();
const publicProjectStore = usePublicProjectStore();
const { bidPackage } = storeToRefs(useBidPackageStore());
const defaultEstimatorTableColumns = [
  {
    field: 'id',
    label: 'ID',
    width: 40,
    numeric: true,
  },
  {
    field: 'company_name',
    label: 'Company',
    sortable: true,
  },
  {
    field: 'name',
    label: 'Estimator',
    sortable: true,
  },
  {
    field: 'phone_number',
    label: 'Phone',
    sortable: true,
  },
  {
    field: 'extension',
    label: 'Extension',
    sortable: true,
  },
  {
    field: 'fax_number',
    label: 'Fax',
    sortable: true,
  },
  {
    field: 'email',
    label: 'Email',
    sortable: true,
  },
];
const defaultContactTableColumns = [
  {
    field: 'id',
    label: 'ID',
    width: 40,
    numeric: true,
  },
  {
    field: 'label',
    label: 'Type',
    sortable: true,
  },
  {
    field: 'company_name',
    label: 'Company',
    sortable: true,
  },
  {
    field: 'name',
    label: 'Estimator',
    sortable: true,
  },
  {
    field: 'phone_number',
    label: 'Phone',
    sortable: true,
  },
  {
    field: 'extension',
    label: 'Extension',
    sortable: true,
  },
  {
    field: 'fax_number',
    label: 'Fax',
    sortable: true,
  },
  {
    field: 'email',
    label: 'Email',
    sortable: true,
  },
];

const props = withDefaults(defineProps<{
  bidPackageId: number | null,
  publicProjectView?: boolean,
}>(), {
  bidPackageId: null,
  publicProjectView: false,
});
const {
  bidPackageId,
  publicProjectView,
} = toRefs(props);

const estimators = computed(() => (bidPackageId.value ? bidPackage.value?.estimators : project.value?.estimators) || []);

const contacts = computed(() => (bidPackageId.value ? bidPackage.value?.project_contacts : project.value?.project_contacts) || []);

const project = computed(() => publicProjectView.value ? publicProjectStore.project : projectStore.project);

</script>

<style lang="scss" scoped>

#contacts-tab {

  .box {
    overflow-x: auto;
  }

}

</style>

<style lang="scss">

#contacts-tab {

}

</style>