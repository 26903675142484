import Edit from "@/pages/Company/Edit.vue";

export default [
  {
    name: 'company/edit',
    path: '/company/edit',
    component: Edit,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
      ],
      icon: 'user',
      title: 'Update My Info',
    },
  },
]
