<template>
    <span>Logging out...</span>
</template>

<script setup lang="ts">

    import { onBeforeMount } from 'vue';
    import Auth from "@/auth";

    onBeforeMount(async () => {
      await Auth.authenticate();
      Auth.logout();
    });

</script>
