import type {APIResponse} from '@/api/Api';
import {Api} from '@/api/Api';
import {deserialize} from './Deserializer';
import type {BidPackage} from '@/models/BidPackage';
import {BidPackageSchema} from "@/models/BidPackage";
import {utcToLocal} from "@/internal";

export default class BidPackageAPI extends Api {

  show(id: number, projectId: number, params: any): Promise<BidPackage> {
    return this.get({
      url: `projects/${projectId}/bid-packages/${id}`,
      queryParams: params
    })
    .then((res) => this.processResponse(res))
    .then((res) => {
      res.data.attributes.sub_bid_due = res.data.attributes.sub_bid_date && res.data.attributes.sub_bid_time ? utcToLocal(res.data.attributes.sub_bid_due) : (res.data.attributes.sub_bid_date ? res.data.attributes.sub_bid_date : res.data.attributes.sub_bid_time);
      res.data.attributes.site_meeting_at = res.data.attributes.site_meeting_date && res.data.attributes.site_meeting_time ? utcToLocal(res.data.attributes.site_meeting_at) : (res.data.attributes.site_meeting_date ? res.data.attributes.site_meeting_date : res.data.attributes.site_meeting_time);
      return res;
    })
    .then(res => deserialize<BidPackage>(res, BidPackageSchema) as BidPackage);
  }
};
