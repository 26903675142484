<template>
    <nav id="navigation-global" class="dark" :style="`background-color: ${menuBackgroundColor};`">
        <div class="is-top">
          <NavMenu dark :menu="menu" :menu-background-color="menuBackgroundColor"  :menu-text-color="menuTextColor" />
          <NavMenu dark :menu="primaryMenu" :menu-background-color="menuBackgroundColor"  :menu-text-color="menuTextColor" />
        </div>

        <div class="is-bottom">
          <NavMenu dark :menu="bottomMenu" :menu-background-color="menuBackgroundColor"  :menu-text-color="menuTextColor" />
        </div>
    </nav>
</template>

<script setup lang="ts">

  import { ref, computed, onMounted, watch } from "vue";

  import {
    findRouteByName,
  } from "@/internal";

  import globalMenu from '@/menus/global';
  import globalPrimaryMenu from '@/menus/global-primary';
  import NavMenu from '@/components/NavMenu.vue';
  import userMenu from '@/menus/user';
  import {useRoute, useRouter} from "vue-router";
  import type Menu from "@/use/Menu";
  import {storeToRefs} from "pinia";
  import {useCompanyStore} from "@/stores/CompanyStore";
  import {useSystemStore} from "@/stores/SystemStore";

  const { company } = storeToRefs(useCompanyStore());
  const systemStore = useSystemStore();
  const route = useRoute();
  const router = useRouter();
  const toggleNavPanel = systemStore.toggleNavPanel;
  const user = {};

  const menu = computed<Menu>(() => {
    return globalMenu({
      handleFavoritesClick: () => toggleNavPanel('favorites'),
      handleSearchClick: () => toggleNavPanel('search'),
      handleAddClick: () => toggleNavPanel('add'),
      hideTitles: true,
    });
  });

  const menuBackgroundColor = computed<string>(() => company.value?.client?.sp_menu_background ? `#${company.value.client.sp_menu_background}` : '#0067b8');

  const menuTextColor = computed<string>(() => company.value?.client?.sp_menu_color ? `#${company.value.client.sp_menu_color}` : '#FFFFFF');

  const primaryMenu = computed<Menu>(() => {
    return globalPrimaryMenu({
      user: user,
    });
  });

  const bottomMenu = computed<Menu>(() => {
    return userMenu({
      handleAccountClick: () => toggleNavPanel('account'),
      handleHelpClick: () => toggleNavPanel('help'),
      handleSystemPreferencesClick: async () => {
        const route = findRouteByName('settings/system-preferences');
        await router.push(route);
      },
      handleLogOutClick: async () => {
        const route = findRouteByName('logout');
        await router.push(route);
      },
      handleSuperClick: () => toggleNavPanel('super'),
      user: user,
      hideTitles: true,
      numUnreadNotifications: null,
    });
  });

</script>

<style lang="scss" scoped>
    #navigation-global {
        background: $primary-light;
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
        padding: 5px 12.5px;
        z-index: 2000;

        &.dark {
          background: $primary;
        }
    }
</style>