import {defineStore} from "pinia";
import ProjectAPI from "@/api/ProjectAPI";
import { StorageSerializers, syncRef, useAsyncState, useStorage } from '@vueuse/core';
import {computed, ref, watch} from "vue";
import {useRoute} from "vue-router";
import type {Project} from "@/models/Project";
import {useBidPackageStore} from "@/stores/BidPackageStore";

export const useProjectStore = defineStore('project', () => {
    const route = useRoute();
    const isLoading = ref<boolean>(false);
    const viewBidPackageId = ref<number | null>(null);

    const project = useStorage<Project | undefined>('project', null, sessionStorage, {
        serializer: StorageSerializers.object,
    });

    const bidPackageStore = useBidPackageStore();

    const clear = () => {
        project.value = null;
        setViewBidPackageId(null);
    };

    const setViewBidPackageId = (bidPackageId: number | null) => viewBidPackageId.value = bidPackageId;

    const show = async (projectId: number, payload: any = {params: null}) => {
        isLoading.value = true;
        clear();
        bidPackageStore.clear();

        const { params } = payload;
        await new ProjectAPI().show(projectId, params)
            .then(data => {
                project.value = data;
            })
            .finally(() => isLoading.value = false);
    }

    return {
        project,
        isLoading,
        viewBidPackageId,
        setViewBidPackageId,
        show,
    };
});
