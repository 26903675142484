export default class MenuItem {

  activeWithParent: boolean;
  count: number | null;
  displayInactive: boolean;
  hideTitle: boolean;
  icon: string | null;
  iconPack: string | null;
  imageUrl: string | null;
  menuItems: MenuItem[];
  notificationCount: number | null;
  onClick: Function | null;
  route: any | null;
  secondaryMenuActive: boolean;
  title: string;
  usePsIcon: boolean;
  userId: number | null;
  when: boolean | null;

  constructor(config: any = {}) {

    const routeMeta = config.route && config.route.meta ? config.route.meta : {};

    this.activeWithParent = config.activeWithParent || false;
    this.count = config.count || null;
    this.displayInactive = config.displayInactive || false;
    this.hideTitle = config.hideTitle || false;
    this.icon = config.icon || routeMeta.icon || null; // icon prop
    this.iconPack = config.iconPack || routeMeta.iconPack || null; // icon prop
    this.imageUrl = config.imageUrl || null;
    this.menuItems = config.menuItems ? config.menuItems.map((menuItem: any) => new MenuItem(menuItem)) : [];
    this.notificationCount = config.notificationCount || false;
    this.onClick = config.onClick || null;
    this.route = config.route || {}; // Vue Router Route
    this.secondaryMenuActive = config.secondaryMenuActive || false;
    this.title = config.title || routeMeta.title || '';
    this.usePsIcon = config.usePsIcon || false;
    this.userId = config.userId || null;
    this.when = config.when !== undefined ? config.when : null;
  }

  get shouldShowTitle() {
    return this.title && !this.hideTitle;
  }
}