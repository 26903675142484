import { useProgrammatic } from '@oruga-ui/oruga-next';

import DialogDisplay from '@/components/DialogDisplay.vue';

const {oruga} = useProgrammatic();

export default function Dialog(config: any) {

  const newConfig = {...config};

  oruga.notification.open({
    component: DialogDisplay,
    props: {
      ...config,
    },
    indefinite: true,
    position: 'top',
    variant: 'success',
    ...newConfig,
  });
};
