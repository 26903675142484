import { z } from "zod";
import {utcToLocal} from "@/internal";
import {TradeSchema} from "@/models/Trade";

export const OPRFileSchema = z.object({
    id: z.coerce.number().optional(),
    project_id: z.coerce.number().optional(),
    bid_package_id: z.coerce.number().optional(),
    company_id: z.coerce.number().optional(),
    trade_id: z.coerce.number().optional(),
    client_id: z.coerce.number().optional(),
    folder_id: z.coerce.number().optional(),
    name: z.string().optional().nullable(),
    description: z.string().optional().nullable(),
    extension: z.string().optional().nullable(),
    file_path: z.string().optional().nullable(),
    file_byte_size: z.coerce.number().optional().nullable(),
    attached: z.boolean().optional().nullable(),
    included_in_bid_packages: z.boolean().optional().nullable(),
    uploaded_by: z.string().optional().nullable(),

    uploaded_at: z.string().transform((val:string|null) => val ? utcToLocal(val) : '').nullable(),

    trade: TradeSchema.optional().nullable().default(null),
});

export type OPRFile = z.infer<typeof OPRFileSchema>;