<template>
  <div class="company-form">
    <o-loading
        :full-page="true"
        v-model:active="isLoading"
        icon="rotate"
        icon-size="large"
    />

    <div class="box updates-pending" v-if="companyUpdates && companyUpdates.length">
      <div class="label">Pending Updates</div>
      <div>The updates below are pending approval. Check back later to see if your updates were accepted by your contractor.</div>

      <div v-for="update in companyUpdates" class="has-margin-top-large" @click="toggleCompanyUpdate(update.id)">
        <div class="is-flex is-align-items-center">
          <div class="is-link">Update #{{update.id}}</div>
          <o-icon class="has-margin-left-small" :icon="showCompanyUpdate[update.id] ? 'caret-down' : 'caret-up'" pack="fas" variant="secondary" />
        </div>
        <div class="box updates-pending update is-flex" v-if="showCompanyUpdate[update.id]">
          <div class="column">
            <template v-for="(value, key) in update">
              <div v-if="!['id', 'contact_update', 'submitted_on'].includes(<string><unknown>key) && value !== '<![NULL]>'" class="is-flex">
                <div class="label has-margin-right">{{label(`${key}`)}}: </div>
                <div>{{value}}</div>
              </div>
            </template>
            <template v-for="(value, key) in update.contact_update">
              <div v-if="!['id', 'contact_update', 'submitted_on'].includes(<string><unknown>key) && value !== '<![NULL]>'" class="is-flex">
                <div class="label has-margin-right">{{label(`${key}`)}}: </div>
                <div>{{value}}</div>
              </div>
            </template>
          </div>
          <div class="column">
            <div class="button is-primary" @click="handleDeleteUpdate(update.id)">Cancel Update</div>
          </div>
        </div>
      </div>
    </div>

    <div class="has-margin-bottom-large">
      <o-field label="Company Name*"
               :variant="error('name') ? 'danger' : ''"
               :message="error('name')"
      >
        <o-input v-model="companyForm.name" required></o-input>
      </o-field>

      <o-field label="Contact First Name"
               :variant="error('primary_contact.first_name') ? 'danger' : ''"
               :message="error('primary_contact.first_name')"
      >
        <o-input v-model="companyForm.primary_contact.first_name"></o-input>
      </o-field>

      <o-field label="Contact Last Name"
               :variant="error('primary_contact.last_name') ? 'danger' : ''"
               :message="error('primary_contact.last_name')"
      >
        <o-input v-model="companyForm.primary_contact.last_name"></o-input>
      </o-field>

      <o-field label="Address"
               :variant="error('address') ? 'danger' : ''"
               :message="error('address')"
      >
        <o-input v-model="companyForm.address"></o-input>
      </o-field>

      <o-field
               :variant="error('address2') ? 'danger' : ''"
               :message="error('address2')"
      >
        <o-input v-model="companyForm.address2"></o-input>
      </o-field>

      <o-field label="City"
               :variant="error('city') ? 'danger' : ''"
               :message="error('city')"
      >
        <o-input v-model="companyForm.city"></o-input>
      </o-field>

      <o-field label="State"
               :variant="error('state') ? 'danger' : ''"
               :message="error('state')"
      >
        <o-input v-model="companyForm.state"></o-input>
      </o-field>

      <o-field label="Zip"
               :variant="error('zip') ? 'danger' : ''"
               :message="error('zip')"
      >
        <o-input v-model="companyForm.address2"></o-input>
      </o-field>

      <o-field label="Country"
               :variant="error('country') ? 'danger' : ''"
               :message="error('country')"
      >
        <o-input v-model="companyForm.country"></o-input>
      </o-field>

      <o-field label="Phone"
               :variant="error('primary_contact.phone_number') ? 'danger' : ''"
               :message="error('primary_contact.phone_number')"
      >
        <o-input v-model="companyForm.primary_contact.phone_number"></o-input>
      </o-field>

      <o-field label="Extension"
               :variant="error('primary_contact.extension') ? 'danger' : ''"
               :message="error('primary_contact.extension')"
      >
        <o-input v-model="companyForm.primary_contact.extension"></o-input>
      </o-field>

      <o-field label="Fax"
               :variant="error('primary_contact.fax_number') ? 'danger' : ''"
               :message="error('primary_contact.fax_number')"
      >
        <o-input v-model="companyForm.primary_contact.fax_number"></o-input>
      </o-field>

      <o-field label="Cell"
               :variant="error('primary_contact.cell_number') ? 'danger' : ''"
               :message="error('primary_contact.cell_number')"
      >
        <o-input v-model="companyForm.primary_contact.cell_number"></o-input>
      </o-field>

      <o-field label="Email"
               :variant="error('primary_contact.email') ? 'danger' : ''"
               :message="error('primary_contact.email') || ''"
      >
        <div class="is-flex is-flex-direction-column emails">
        <div class="help has-margin-bottom">Separate multiple emails with a comma</div>
        <o-input v-model="companyForm.primary_contact.email" type="text"></o-input>
        </div>
      </o-field>

    </div>

    <div class="has-text-right">
      <o-button class="is-primary has-margin-right" @click="handleCancel">Cancel</o-button>
      <o-button class="is-primary" @click="handleSave">Save</o-button>
    </div>

  </div>
</template>

<script setup lang="ts">

import {
  NotificationError,
  NotificationSuccess,
} from "@/internal";

import {computed, onMounted, ref, toRefs, watch} from "vue";
import {storeToRefs} from "pinia";
import _ from 'lodash';
import {type Company, CompanySchema} from "@/models/Company";
import {type CompanyUpdate} from "@/models/CompanyUpdate";
import {useCompanyStore} from "@/stores/CompanyStore";
import {useCompanyUpdateStore} from "@/stores/CompanyUpdateStore";

const companyStore = useCompanyStore();
const companyUpdateStore = useCompanyUpdateStore();
const {company, isLoading} = storeToRefs(companyStore);
const {companyUpdates} = storeToRefs(companyUpdateStore);
const errors = ref(<any>[]);
const companyForm = ref<Company>(<Company>{...company.value});
const showCompanyUpdate = ref<Record<number, boolean>>({});

const emit = defineEmits<{
  'saved': [],
}>();

function clearErrors() {
  errors.value = [];
}

function error(key: string) {
  return errors.value?.find((error: any) => error.hasOwnProperty('key') && error.key === key)?.detail || null;
}

function handleCancel() {
  clearErrors();
  companyForm.value = company.value;
}

function handleDeleteUpdate(updateId: number) {
  companyUpdateStore.destroy(updateId).then(() => {
    NotificationSuccess({
      message: `Company Update Cancelled`,
    });

    emit('saved');
  }).catch((error: any) => {
    if (error.hasOwnProperty('errors')) {
      errors.value = error.errors;
    }
    else {
      NotificationError({
        message: error,
      });
    }
  });
}

function handleSave() {

  clearErrors();

  const payload: any = {
    ...companyForm.value,
    primary_contact: {
      ...companyForm.value?.primary_contact,
    },
    project_id: window.sessionStorage.getItem("project_id"),
  };

  companyStore.update(payload).then((response: Company) => {
    NotificationSuccess({
      message: `Company Update Requested`,
    });

    emit('saved');
  }).catch((error: any) => {
    if (error.hasOwnProperty('errors')) {
      errors.value = error.errors;
    }
    else {
      NotificationError({
        message: error,
      });
    }
  });

}

function label(name: string) {
  return _.startCase(name.replace("_", " "));
}

function toggleCompanyUpdate(updateId: number) {
  showCompanyUpdate.value[updateId] = !showCompanyUpdate.value[updateId];
}

watch(company, () => {
  companyForm.value = <Company>{
    ...company.value,
  };
});

watch(companyUpdates, () => {
  showCompanyUpdate.value = {};
  if (companyUpdates.value?.length) {
    companyUpdates.value.forEach((update: CompanyUpdate) => {
      showCompanyUpdate.value[update.id] = false;
    })
  }
});

</script>

<style lang="scss" scoped>

.company-form {

  .field {
    .emails {
      width: 100%;
    }
  }

  .updates-pending {
    background-color: lightyellow;

    &.update {
      border-color: $primary;
    }
  }
}

</style>

<style lang="scss">

.company-form {
}

</style>
