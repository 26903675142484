<template>
  <Page :title="pageTitle" id="company">
    <o-loading
        :full-page="true"
        :active="isLoading"
        icon="rotate"
        icon-size="large"
    />
    <template v-if="company">
      <div v-if="usePrequalForm && prequalFormUrl">
        <iframe :src="prequalFormUrl" style="width: 800px; height: 800px;" />
      </div>
      <div v-else>
        <Form @saved="handleSaved" />
      </div>
    </template>
  </Page>
</template>

<script setup lang="ts">

import Page from "@/components/Page.vue";
import Form from "./Form.vue";
import {NotificationError} from "@/internal";
import {storeToRefs} from "pinia";
import {computed, onMounted} from "vue";
import {useCompanyStore} from "@/stores/CompanyStore";
import {useCompanyUpdateStore} from "@/stores/CompanyUpdateStore";

const companyStore = useCompanyStore();
const companyUpdateStore = useCompanyUpdateStore();
const {company, isLoading} = storeToRefs(companyStore);

const client = computed(() => company.value?.client);

const pageTitle = computed(() => company.value?.name || 'Update My Info');

const prequalFormUrl = computed(() => company.value?.prequal_url || '');

const usePrequalForm = computed(() => company.value?.prequal_url && client.value?.prequal_activated && client.value?.use_prequal_on_portal && client.value?.portal_prequal_form_id)

onMounted(() => {
  handleSaved();
});

function handleSaved() {
  loadCompany();
  loadCompanyUpdates();
}

function loadCompany() {
  const payload = {
    params: {
      include: [
        'client',
        'primaryContact',
      ],
    }
  };

  companyStore.getCompany(payload)
    .catch(error => {
      if (error.errors) {
        NotificationError({}, error);
      } else {
        NotificationError({
          message: error,
        });
      }
    });
}

function loadCompanyUpdates() {
  const payload = {
    params: {
      include: [
      ],
    }
  };

  companyUpdateStore.index(payload)
    .catch(error => {
      if (error.errors) {
        NotificationError({}, error);
      } else {
        NotificationError({
          message: error,
        });
      }
    });
}

</script>

<style scoped lang="scss">

#company {

}

</style>