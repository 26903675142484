import { CaseType, deserialize as jsonApiDeserialize } from 'jsonapi-fractal';

import type { ZodSchema } from 'zod';
import type { DocumentObject } from 'jsonapi-fractal';

export function deserialize<TType>(data: DocumentObject, schema: ZodSchema<any>): TType | TType[] {
    const deserializedData = jsonApiDeserialize<TType>(data, { changeCase: CaseType.snakeCase });
    if(deserializedData === undefined){
        throw Error(`Cannot deserialize data: ${data}`);
    }
    try {
        if (Array.isArray(deserializedData)) {
            const transformedData = [];
            for (const i of deserializedData) {
                transformedData.push(schema.parse(i) as TType);
            }
            return transformedData;
        }
        return schema.parse(deserializedData) as TType;
    } catch(e) {
        console.error(e);
        throw new Error(`Cannot Deserialize: ${data}`);
    }
}
