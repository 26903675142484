import {defineStore} from "pinia";
import CompanyUpdateAPI from "@/api/CompanyUpdateAPI";
import {StorageSerializers, useStorage} from "@vueuse/core";
import {ref} from "vue";

export const useCompanyUpdateStore = defineStore('company-updates', () => {

  const companyUpdates = useStorage('company-updates', null, sessionStorage, {
    serializer: StorageSerializers.object,
  });
  const isLoading = ref<boolean>(false);

  const clear = () => {
    companyUpdates.value = null;
  };

  const index = async (payload: any = { params: null}) => {
    const { params } = payload;

    isLoading.value = true;
    clear();
    await new CompanyUpdateAPI().index(params)
      .then(data => {
        companyUpdates.value = data;
      })
      .finally(() => isLoading.value = false);
  };

  const destroy = async (id: number) => {
    isLoading.value = true;
    await new CompanyUpdateAPI().destroy(id)
        .finally(() => {
          isLoading.value = false;
        });
  };

  return {
    companyUpdates,
    isLoading,
    index,
    destroy,
  };
});
