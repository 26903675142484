import { z } from "zod";

export const ClientSchema = z.object({
    id: z.coerce.number().optional(),
    name: z.string().optional().nullable(),
    prequal_activated: z.boolean().optional().nullable(),
    use_prequal_on_portal: z.boolean().optional().nullable(),
    portal_prequal_form_id: z.coerce.number().optional().nullable(),
    show_rfi: z.boolean().optional().nullable(),
    show_bid_upload: z.boolean().optional().nullable(),
    bid_forms_hide_upload: z.boolean().optional().nullable(),
    sp_menu_background: z.string().optional().nullable(),
    sp_menu_color: z.string().optional().nullable(),
    logo_url: z.string().optional().nullable(),
});

export type Client = z.infer<typeof ClientSchema>;