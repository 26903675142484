import _ from 'lodash';

export default class FormErrors {

  bag: any;

  constructor() {
    this.bag = {};
  }

  record(errors: any) {
    this.bag = {};

    if (Array.isArray(errors)) {
      errors.forEach(error => {
        if (error.key) {
          this.bag[_.camelCase(error.key)] = error.detail;
        }
      });
    } else {
      let key: string;
      let value: any;
      for ([key, value] of Object.entries(errors)) {

        let message: string = '';

        if (Array.isArray(value)) {
          message = value.join(' ');
        } else {
          message = value;
        }

        this.bag[_.camelCase(key)] = message;
      }
    }
  }

  get(field: string) {
    if (this.bag[_.camelCase(field)]) {
      return this.bag[_.camelCase(field)];
    }

    return null;
  }

  has(field: string) {
    return this.bag.hasOwnProperty(_.camelCase(field));
  }

  clear(field: string | null = null) {

    if (field) {
      delete this.bag[field];
      return;
    }

    this.bag = {};
  }

  any() {
    return Object.keys(this.bag).length > 0;
  }
}