<template>
  <o-menu-item :icon="item.icon"
               :icon-pack="item.iconPack"
               :item-class="itemClasses"
               item-submenu-class="sub-menu"
               expanded
               @click="handleMenuItem(item.route)"
               class="menu-item"
               :style="menuTextColor ? `color: ${menuTextColor};` : ''"
               v-if="item.menuItems && item.menuItems.length"
  >
    <template #label="props">
      <span>{{item.title}}</span>
    </template>
    <template v-for="(subItem, j) in item.menuItems">
      <NavMenuItem :item="subItem" :menu-text-color="menuTextColor" />
    </template>
  </o-menu-item>
  <o-menu-item :icon="item.icon"
               :icon-pack="item.iconPack"
               :label="item.shouldShowTitle ? item.title : ''"
               :item-class="itemClasses"
               @click="handleMenuItem(item.route)"
               :title="item.title || ''"
               class="menu-item"
               :style="menuTextColor ? `color: ${menuTextColor};` : ''"
               :onmouseover="`this.style.backgroundColor='color-mix(in srgb, ${menuBackgroundColor} 80%, white)'`"
               :onmouseout="`this.style.backgroundColor='${menuBackgroundColor}'; this.style.color='${menuTextColor}'`"
               v-else
  />
</template>

<script setup lang="ts">

  import {ref, computed, onMounted, watch, toRefs} from "vue";
  import {type RouteLocationNormalized, useRoute, useRouter} from "vue-router";

  let numUnreadNotifications = ref(0);
  const subMenuActive = ref(false);

  const props = withDefaults(defineProps<{
    item: Record<any, any>,
    menuBackgroundColor?: string | null,
    menuTextColor?: string | null,
  }>(), {
    menuBackgroundColor: null,
    menuTextColor: null,
  });
  const {
    item,
    menuBackgroundColor,
    menuTextColor,
  } = toRefs(props);
  const itemProp = item.value;

  onMounted(() => {
  });

  const currentRoute = useRoute();
  const router = useRouter();

  function handleMenuItem(route: RouteLocationNormalized) {
    if (itemProp.onClick) {
      itemProp.onClick();
    }
    else {
      router.push({
        name: route.name || undefined,
        params: route.params,
      });
    }
  }

  const itemClasses = computed(() => {
    const classes = ['menu-item'];

    if (!itemProp.shouldShowTitle) {
      classes.push('icon-only');
    }

    if (item.value.route && !item.value.displayInactive && ((currentRoute.name === item.value.route.name)
        || (item.value.activeWithParent && item.value.route.name.startsWith(`${<string>currentRoute.name}/`))
        || (currentRoute.name !== undefined && (<string>currentRoute.name).startsWith(`${item.value.route.name}/`)))) {
      classes.push('item-active');
      subMenuActive.value = true;
    }
    else if (currentRoute.name !== undefined && !(<string>currentRoute.name).startsWith(`${item.value.route.name}/`)) {
      subMenuActive.value = false;
    }

    return classes.join(' ');
  });

</script>

<style lang="scss" scoped>
    .nav-menu-item-liner {
        align-items: center;
        display: flex;
        position: relative;

        .nav-menu-item-count {
            background: $grey-lightest;
            border-radius: 20px;
            color: $black;
            font-size: 12px;
            padding: 0 5px;
            position: absolute;
            top: -12.5px;
            right: -5px;
            transform: translateX(50%);
        }
    }
</style>

<style lang="scss">
</style>
