<template>
  <o-tooltip :label="value" multiline>
    <div @click="handleClick" class="name-column is-link truncate">
        {{value}}
    </div>
  </o-tooltip>
</template>

<script setup lang="ts">

  import {toRefs} from "vue";

  const props = withDefaults(defineProps<{
    row: any,
    value: string
  }>(), {
  });
  const {
    row,
    value,
  } = toRefs(props);

  const emit = defineEmits<{
    'click': [any],
  }>();

  function handleClick() {
    emit('click', row.value);
  }

</script>

<style scoped lang="scss">
    .name-column {
      max-width: 200px;
    }
</style>