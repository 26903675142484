import type {APIResponse} from '@/api/Api';
import {Api} from '@/api/Api';
import {deserialize} from './Deserializer';
import type {OPRFile} from "@/models/OPRFile";
import {OPRFileSchema} from "@/models/OPRFile";

export default class BidPackageCompanyFileAPI extends Api {

  index(projectId: number, bidPackageId: number, params: any): Promise<OPRFile[]> {
    return this.get({
      url: `projects/${projectId}/bid-packages/${bidPackageId}/files`,
      queryParams: params,
    })
    .then((res) => this.processResponse(res))
    .then(res => deserialize<OPRFile>(res, OPRFileSchema) as OPRFile[]);
  }

  store(projectId: number, bidPackageId: number, data: any): Promise<any> {
    const formData = new FormData(); // pass data as a form

    const files: File[] = data.files;
    delete data.files;

    files.forEach((file: File) => {
      formData.append("files[]", file);
    })

    let key: string;
    let value: any;
    for ([key, value] of Object.entries(data)) {
      formData.append(key, value);
    }

    return this.uploadFile({
      url: `projects/${projectId}/bid-packages/${bidPackageId}/files`,
      headers: {
      },
      body: formData,
    })
    .then((res) => this.processResponse(res))
    .then(res => res);
  }

};