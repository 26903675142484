<template>
  <o-loading
      :full-page="false"
      :active="isFileLoading"
      icon="rotate"
      icon-size="large"
  />
  <div>
    <div v-if="uploadFiles.length">
      <div class="is-flex has-margin-bottom-large" v-for="uploadFile in uploadFiles">
        <a class="button is-secondary is-outlined has-margin-right" :href="fileurl(uploadFile)" target="_blank" v-if="fileurl(uploadFile)">View File</a>
        <span class="help" v-if="uploadFile.name">{{filename(uploadFile.name)}}</span>
        <o-icon icon="trash-alt" title="Remove File" class="has-margin-left" variant="secondary" @click="handleRemoveFile(uploadFile)"/>
      </div>
    </div>
    <div class="file-upload-picker" v-bind="getRootProps()" v-if="multiple || !uploadFiles.length">
      <div class="help" v-if="fileTypesAccepted">File Types Allowed: {{fileTypesAccepted}}</div>
      <input v-bind="getInputProps()" v-if="!disabled" />
      <div class="file-upload content has-text-centered hover-secondary" :class="{'disabled': disabled}">
        <p>
          <o-icon icon="upload" size="medium"/>
        </p>
        <p>{{ dropHereText }}</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

import { useDropzone } from "vue3-dropzone";

import {computed, ref, toRefs, watch} from "vue";
import {
  NotificationError,
} from "@/internal";
import {boolean} from "zod";
import {type OPRFile, OPRFileSchema} from "@/models/OPRFile";
import {isArray} from "lodash";
import {File} from "ps-ui";

const isActionFileLoading = ref(false);
const uploadedFileName = ref('');
const uploadFiles = ref<any[]>([]);
const filteredUpload = ref([
  '.pdf',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
]);

const props = withDefaults(defineProps<{
  action?: Function | null,
  disabled?: boolean,
  params?: Record<string, any> | null,
  data: OPRFile | File | OPRFile[] | File[],
  fileTypes?: string[],
  headers?: {},
  isLoading?: boolean,
  multiple?: boolean,
}>(), {
  action: null,
  disabled: false,
  params: null,
  fileTypes: () => [],
  headers: () => { return {}; },
  isLoading: false,
  multiple: false,
});
const {
  action,
  disabled,
  params,
  data,
  fileTypes,
  headers,
  isLoading,
  multiple,
} = toRefs(props);

const emit = defineEmits([
  'delete',
  'input',
]);

const { getRootProps, getInputProps, ...rest } = useDropzone({
  disabled: disabled.value,
  multiple: multiple.value,
  onDrop,
  accept: fileTypes.value?.length ? fileTypes.value.map((type: string) => <string>type) : undefined,
});

const dropHereText = computed(() => {
  return `Drop your file${ multiple.value === true ? 's' : '' } here or click to upload`;
});

const fileTypesAccepted = computed(() => fileTypes.value && fileTypes.value.length ? fileTypes.value.join(", ") : null);

const fileURL = computed(() => `${import.meta.env.VITE_OPR_URL}${uploadedFileName.value}`);

const isFileLoading= computed(() => isLoading.value || isActionFileLoading.value);

const useHeaders = computed(() => {
  return {
    "Content-Type": "multipart/form-data",
    ...headers.value,
  }
});

function filename(value: string) {
  return value?.substring(value.lastIndexOf('/')+1);
}

function fileurl(file: OPRFile | File) {
  return file.hasOwnProperty('file_path') ? file.file_path : null;
}

function handleRemoveFile(file: OPRFile | File) {
  uploadFiles.value = uploadFiles.value.filter((uploadFile: OPRFile | File) => (uploadFile.hasOwnProperty('id') && file.hasOwnProperty('id') && uploadFile.id !== file.id) || uploadFile !== file);
  emit("delete", uploadFiles.value);
}

function onDrop(acceptFiles: File[], rejectReasons: any) {
  if (rejectReasons?.length && rejectReasons[0].errors?.length) {
    NotificationError({}, rejectReasons[0]);
  }
  else {
    emit('input', acceptFiles);

    if (action.value) {
      saveFiles(acceptFiles);
    }
  }
}

function saveFiles(files: File[]) {

  if (!files || !files.length || !action.value) {
    return;
  }

  isActionFileLoading.value = true;
  action.value(files, params.value)
    .then((response: any) => {
      uploadedFileName.value = response.data?.filename || '';
    })
    .catch((err: any) => {
      NotificationError({
        message: 'Server Error',
      });
      console.error(err);
    })
    .finally(() =>   isActionFileLoading.value = false);
}

watch(data, () => {
  uploadFiles.value = isArray(data.value) ? data.value : [data.value];
});

</script>

<style lang="scss" scoped>

.file-upload-picker {
  .file-upload {
    display: block;
    padding: .25em;
    border: 1px dashed #b5b5b5;
    border-radius: 6px;
    cursor: pointer;

    &.hover-secondary {
      &:hover {
        border-color: #0067b8;
        background-color: rgba(0, 103, 184, 0.05);
      }

      &.disabled {
        cursor: no-drop;
        opacity: 50%;
      }
    }

    &.hover-primary {
      &:hover {
        border-color: #107e67;
        background-color: rgba(62, 61, 178, 0.05);
      }
    }
  }

  #file-upload-status {
    font-style: italic;
  }
}

</style>
