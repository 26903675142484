<template>
  <div id="info-tab" class="tab is-full-height">
    <div class="label">{{ bidPackageId ? 'Bid Package' : 'Project' }} Info</div>

    <div class="info is-flex is-full-height">
      <div class="column has-margin-right is-half-desktop is-half-widescreen is-one-third-fullhd is-full-tablet is-full-mobile overflow-scroll">
        <o-field class="alt-row" label="Project #" horizontal>{{project?.number}}</o-field>

        <o-field :label="`${bidPackageId ? 'Bid Package' : 'Project'} Name`" horizontal>{{ bidPackageId ? bidPackage?.name : project?.name }}</o-field>

        <o-field class="alt-row" label="Open Invitation" horizontal>{{ project?.open_invitation ? 'Yes' : 'No' }}</o-field>

        <o-field label="Invited" horizontal>{{ project?.invited ? 'Yes' : 'No' }}</o-field>

        <o-field class="alt-row" label="Location" horizontal>{{ project?.location }}</o-field>

        <o-field label="Address" horizontal>
          <div>
            <div>{{ project?.address }}</div>
            <div v-if="mapSrc">
              <iframe width="200"
                      height="400"
                      style="border:0"
                      class="map-iframe"
                      :src="mapSrc"
                      allowfullscreen
              />
            </div>
          </div>
        </o-field>

        <o-field class="alt-row" label="City" horizontal>{{ project?.city }}</o-field>

        <o-field label="State" horizontal>{{ project?.state }}</o-field>

        <o-field class="alt-row" label="Zip" horizontal>{{ project?.zip }}</o-field>

        <o-field label="Bid Due" horizontal>{{ bidPackageId ? bidPackage?.sub_bid_due : project?.sub_bid_due }}</o-field>

        <o-field class="alt-row" label="Site Meeting Date/Time" horizontal>{{ bidPackageId ? bidPackage?.site_meeting_at : project?.site_meeting_at }}</o-field>
      </div>

      <div class="column is-half-desktop is-half-widescreen is-two-thirds-fullhd is-full-tablet is-full-mobile overflow-scroll">
        <o-field class="alt-row" label="Scope" horizontal><HTMLText :value="bidPackageId ? bidPackage?.scope : project?.scope" /></o-field>

        <o-field label="Plans" horizontal><HTMLText :value="bidPackageId ? bidPackage?.exclusions : project?.exclusions" /></o-field>

        <o-field class="alt-row" label="Other Info" horizontal><HTMLText :value="bidPackageId ? bidPackage?.other_info : project?.other_info" /></o-field>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

import {computed, onMounted, ref, toRefs, watch} from "vue";
import {storeToRefs} from "pinia";
import HTMLText from "@/components/HTMLText.vue";
import {usePublicProjectStore} from "@/stores/PublicProjectStore";
import {useProjectStore} from "@/stores/ProjectStore";
import {useBidPackageStore} from "@/stores/BidPackageStore";

const projectStore = useProjectStore();
const publicProjectStore = usePublicProjectStore();
const { bidPackage } = storeToRefs(useBidPackageStore());

const props = withDefaults(defineProps<{
  bidPackageId: number | null,
  publicProjectView?: boolean,
}>(), {
  bidPackageId: null,
  publicProjectView: false,
});
const {
  bidPackageId,
  publicProjectView,
} = toRefs(props);

const fullAddress = computed(() => `${project.value?.address},${project.value?.city},${project.value?.state},${project.value?.zip}`);

const hasAddress = computed(() => project.value?.address || project.value?.city || project.value?.state || project.value?.zip);

const mapSrc = computed(() => {
  if (hasAddress.value) {
    const q = encodeURI(fullAddress.value);
    const key = import.meta.env.VITE_APP_GOOGLE_MAPS_API_KEY;
    return `https://www.google.com/maps/embed/v1/place?key=${key}&q=${q}&zoom=15`;
  }

  return '';
});

const project = computed(() => publicProjectView.value ? publicProjectStore.project : projectStore.project);

</script>

<style lang="scss" scoped>

#info-tab {
  .field {
    padding: 3px;

    &.alt-row {
      background-color: #f4f9fd;
    }
  }

  .field-label {
    .label {
      min-width: 200px;
    }
  }

  .info {
    .column {
      white-space: break-spaces;
    }

    .overflow-scroll {
      overflow-x: auto;
    }

    .map-iframe {
      border-radius: 5px;
      height: 225px;
    }
  }
}

@media (max-width: 1023px) {
  #info-tab {
    .info {
      flex-direction: column;
    }
  }
}

</style>

<style lang="scss">

#info-tab {
  .field-label {
    .label {
      width: 150px;
      text-wrap: wrap;
      margin-top: 2px !important;
    }
  }

}

</style>