import { z } from "zod";
import {utcToLocal} from "@/internal";

export const ContactUpdateSchema = z.object({
    id: z.coerce.number().optional(),
    first_name: z.string().optional().nullable(),
    last_name: z.string().optional().nullable(),
    phone_number: z.string().optional().nullable(),
    extension: z.string().optional().nullable(),
    fax_number: z.string().optional().nullable(),
    cell_number: z.string().optional().nullable(),
    email: z.string().optional().nullable(),
    // submitted_on: z.string().transform((val:string) => utcToLocal(val)).nullable(),
});

export type ContactUpdate = z.infer<typeof ContactUpdateSchema>;