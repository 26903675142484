<template>
    <div class="trade-column">
        {{tradeName}}
    </div>
</template>

<script setup lang="ts">

import {computed, toRefs} from "vue";
import type {Trade} from "@/models/Trade";

const props = withDefaults(defineProps<{
  row: any,
  value: Trade,
}>(),  {
});
const {
  row,
  value,
} = toRefs(props);

const tradeName = computed(() => value.value?.full_label || '');

</script>

<style scoped lang="scss">
    .trade-column {
      width: 200px;
      max-width: 200px;
      white-space: break-spaces;
    }
</style>