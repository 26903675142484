<template>
  <div id="notices-tab" class="tab is-full-height">
    <div class="label">Notices</div>

    <IndexTable
        :columns="defaultTableColumns"
        default-status-filter="active"
        :display-status-filter="false"
        :items="notices"
        resource="notice"
        title="Notices"
        @click="handleShow"
    />

  </div>

  <o-modal v-model:active="isNoticeActive" :width="600">
    <div class="title">{{ selectedNotice?.title }}</div>
    <HTMLText :value="selectedNotice?.scope" />

    <div class="has-text-right">
      <o-button class="is-primary" @click="handleClose">Close</o-button>
    </div>
  </o-modal>
</template>

<script setup lang="ts">

import {computed, ref, toRefs} from "vue";
import {storeToRefs} from "pinia";
import HTMLText from "@/components/HTMLText.vue";
import IndexTable from "@/components/IndexTable.vue";
import type {Notice} from "@/models/Notice";
import {usePublicProjectStore} from "@/stores/PublicProjectStore";
import {useProjectStore} from "@/stores/ProjectStore";
import {useBidPackageStore} from "@/stores/BidPackageStore";

const projectStore = useProjectStore();
const publicProjectStore = usePublicProjectStore();
const { bidPackage } = storeToRefs(useBidPackageStore());
const defaultTableColumns = [
  {
    field: 'id',
    label: 'ID',
    width: 40,
    numeric: true,
  },
  {
    field: 'title',
    label: 'Title',
    component: 'NameColumn',
    sortable: true,
  },
  {
    field: 'created_by',
    label: 'Created By',
    sortable: true,
  },
  {
    field: 'created_at',
    label: 'Created At',
    sortable: true,
  },
];
const isNoticeActive = ref(false);
const selectedNotice = ref<Notice | null>(null);

const props = withDefaults(defineProps<{
  bidPackageId: number | null,
  publicProjectView?: boolean,
}>(), {
  bidPackageId: null,
  publicProjectView: false,
});
const {
  bidPackageId,
  publicProjectView,
} = toRefs(props);

const notices = computed(() => (bidPackageId.value ? bidPackage.value?.notices : project.value?.notices) || []);

const project = computed(() => publicProjectView.value ? publicProjectStore.project : projectStore.project);

function handleClose() {
  isNoticeActive.value = false;
  selectedNotice.value = null;
}

function handleShow(item: Notice) {
  selectedNotice.value = item;
  isNoticeActive.value = true;
}

</script>

<style lang="scss" scoped>

#notices-tab {

}

</style>

<style lang="scss">

#notices-tab {

}

</style>