import {defineStore} from "pinia";
import ProjectAPI from "@/api/ProjectAPI";
import type {ObjectWithMeta} from '@/api/Api';
import {StorageSerializers, useStorage} from "@vueuse/core";
import {ref} from "vue";
import type {Project} from "@/models/Project";

export const useProjectsStore = defineStore('projects', () => {

  const projects = useStorage('projects', [], sessionStorage, {
    serializer: StorageSerializers.object,
  });
  const projectsLinks = useStorage('projects.links', [], sessionStorage, {
    serializer: StorageSerializers.object,
  });
  const projectsMeta = useStorage('projects.meta', [], sessionStorage, {
    serializer: StorageSerializers.object,
  });
  const isLoading = ref<boolean>(false);

  const clear = () => {
    projects.value = null;
    projectsLinks.value = null;
    projectsMeta.value = null;
  };

  const index = async (payload: any) => {
    const { params } = payload;

    isLoading.value = true;
    clear();
    await new ProjectAPI().index(params)
      .then((data: ObjectWithMeta<Project>) => {
        projects.value = data.data;
        projectsLinks.value = data.links;
        projectsMeta.value = data.meta;
      })
      .finally(() => isLoading.value = false);
  }

  return {
    projects,
    projectsLinks,
    projectsMeta,
    isLoading,
    index,
  };
});
