<template>
    <nav id="navigation-contextual">

        <Toggle @click="handleToggleNavigation" :active="!navigationOpen"/>

        <div id="navigation-contextual-content"
             :class="{ 'is-open': navigationOpen }"
        >
            <div id="primary" v-if="!activeRoute.secondaryMenuActive">
                <div class="top"  v-if="navigationOpen">
                    <router-link to="/" class="ps-icon">
                      <img :src="clientLogoURL" :alt="clientName" v-if="clientLogoURL">
                      <div class="label" v-else>{{clientName}}</div>
                    </router-link>

                    <BidPackagesList class="bid-packages" />
                    <NavMenu darks :menu="menu"/>
                </div>
                <div class="bottom" v-if="navigationOpen">
                    <NavMenu darks :menu="bottomMenu"/>
                    <div id="version">v{{version}}</div>
                </div>
            </div>

            <div id="secondary" v-if="activeRoute.secondaryMenuActive">
                <div v-if="navigationOpen">
                    <NavMenu :menu="secondaryMenu" v-if="secondaryMenu"/>
                </div>
            </div>
        </div>
    </nav>
</template>

<script setup lang="ts">

  import { ref, computed, onMounted, watch } from "vue";
  import { useRoute } from "vue-router";

  import appData from '/package';
  import BidPackagesList from "@/pages/Projects/BidPackagesList.vue";
  import primaryMenu from '@/menus/primary';
  import primaryBottomMenu from '@/menus/primary-bottom';
  import NavMenu from '@/components/NavMenu.vue';
  import Toggle from '@/components/Toggle.vue';

  import {
    Menu,
  } from "@/internal";
  import {storeToRefs} from "pinia";
  import {useCompanyStore} from "@/stores/CompanyStore";
  import {useSystemStore} from "@/stores/SystemStore";

  const { company } = storeToRefs(useCompanyStore());
  const system = useSystemStore();
  const user = {};
  const route = useRoute();

  let navigationOpen = ref(true);

  const activeRoute = computed(() => {
    return primaryMenu.menuItems && primaryMenu.menuItems.find((menuItem: Record<any, any>) => menuItem.route && route.name && (<string>route.name).startsWith(menuItem.route.name)) || {};
  });

  const clientLogoURL = computed<string | null>(() => company.value?.client?.logo_url ? company.value.client.logo_url : null);

  const clientName = computed(() => company.value?.client?.name ? company.value.client.name : 'SubsPortal');

  const menu = computed<Menu>(() => {
    return primaryMenu({
      user: user,
      handleTakeOffSoftwareClick: () => {
        window.open(`${import.meta.env.VITE_TAKE_OFF_SOFTWARE_URL}`,'_blank');
      },
    });
  });

  const bottomMenu = computed<Menu>(() => {
    return primaryBottomMenu({
      user: user,
    });
  });

  const secondaryMenu = computed<Menu | null>(() => {
    if (activeRoute.value.menuItems) {
      return new Menu({
        menuItems: activeRoute.value.menuItems,
      });
    }

    return null;
  });

  const version = computed<string>(() => {
    return appData.version;
  });

  function handleToggleNavigation() {
    navigationOpen.value = !navigationOpen.value;
  }

</script>

<style lang="scss">
    #navigation-contextual {
        display: flex;
        position: relative;
        z-index: 2000;


        &:hover {
            .toggle {
                opacity: 1;
            }
        }

        .toggle {
            opacity: 0;
            position: absolute;
            right: 0;
            top: 0;
            transform: translate(50%, 50%);
            transition: all .2s;

            &.is-active {
                opacity: 1;
            }
        }

        #navigation-contextual-content {
          background: $grey-lightest;
          display: flex;
          overflow-x: hidden;
          transition: all .2s;
          width: 0;

          &.is-open {
              width: 250px;
          }

          .bid-packages {
            margin-top: 2em;
            padding-bottom: 1.75em;
            border-bottom: 1px solid $grey-lighter;
          }
        }

        #primary,
        #secondary {
            flex: 1;
            padding: 15px 12.5px;
        }

        #primary {
            background: $grey-lightest;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            #logo {
                color: $white;
                font-family: 'Montserrat', sans-serif;
                font-size: 24px;
                font-weight: 500;
                padding: 5px 15px 0;
            }

            #logo-placeholder {
                height: 48px;
            }

            #version {
                color: $grey;
                font-size: 13px;
                margin-top: 10px;
                text-align: center;
            }
        }

        #secondary {
            background: $white-ter;
            border-right: 1px solid $grey-lightest;

            #object-switcher {
                border: 1px solid $grey;
                border-radius: 4px;
                height: 39px;
                margin-bottom: 7.5px;
                overflow: hidden;
                padding: 7.5px 15px;
                text-align: center;
            }

            #object-switcher-placeholder {
                height: 47px;
            }
        }
    }
</style>
