import Show from "@/pages/PublicProjects/Show.vue";

export default [
  {
    name: 'open-projects/show',
    path: '/open-projects/:projectId/show',
    component: Show,
    meta: {
      breadcrumbs: [
        'open-projects/show',
      ],
      icon: 'inbox-in',
      useModelForTitle: 'project',
    },
  },
]
