<template>
    <nav id="open-navigation-global" class="dark" :style="`background-color: ${menuBackgroundColor};`">
        <div class="is-top">
          <NavMenu dark :menu="menu" :menu-background-color="menuBackgroundColor"  :menu-text-color="menuTextColor" />
        </div>
    </nav>
</template>

<script setup lang="ts">

  import { ref, computed, onMounted, watch } from "vue";

  import globalMenu from '@/menus/global-public';
  import NavMenu from '@/components/NavMenu.vue';
  import {useRoute, useRouter} from "vue-router";
  import type Menu from "@/use/Menu";
  import {storeToRefs} from "pinia";
  import {usePublicProjectStore} from "@/stores/PublicProjectStore";

  const { project, isLoading } = storeToRefs(usePublicProjectStore());
  const route = useRoute();
  const router = useRouter();
  const user = {};

  const menu = computed<Menu>(() => {
    return globalMenu({
      hideTitles: true,
    });
  });

  const menuBackgroundColor = computed<string>(() => isLoading.value ? '#FFFFFF' : (project.value?.client?.sp_menu_background ? `#${project.value.client.sp_menu_background}` : '#0067b8'));

  const menuTextColor = computed<string>(() => project.value?.client?.sp_menu_color ? `#${project.value.client.sp_menu_color}` : '#FFFFFF');

</script>

<style lang="scss" scoped>
    #open-navigation-global {
        background: $primary-light;
        display: flex;
        height: 100%;
        width: 73px;
        flex-direction: column;
        justify-content: space-between;
        padding: 5px 12.5px;
        z-index: 2000;

        &.dark {
          background: $primary;
        }
    }
</style>