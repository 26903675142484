import { createApp }  from 'vue';
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { library } from '@fortawesome/fontawesome-svg-core';
import router from "@/router";
import App from './App.vue'
import Oruga from "@oruga-ui/oruga-next";
import { bulmaConfig } from '@oruga-ui/theme-bulma'

import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

import ExternalFileColumn from '@/components/TableColumns/ExternalFileColumn.vue';
import HTMLColumn from '@/components/TableColumns/HTMLColumn.vue';
import NameColumn from '@/components/TableColumns/NameColumn.vue';
import ProjectInvitedColumn from '@/components/TableColumns/ProjectInvitedColumn.vue';
import ResponseConfirmedColumn from '@/components/TableColumns/ResponseConfirmedColumn.vue';
import TextColumn from '@/components/TableColumns/TextColumn.vue';
import TradeColumn from '@/components/TableColumns/TradeColumn.vue';
import UserNameColumn from '@/components/TableColumns/UserNameColumn.vue';

import {
  faAcorn,
  faAngleLeft,
  faAngleRight,
  faArchive,
  faArrowDown,
  faArrowLeft,
  faArrowAltRight,
  faArrowRight,
  faArrowAltUp,
  faArrowsAlt,
  faArrowTurnDownLeft,
  faArrowUp,
  faArrowsV,
  faBallotCheck,
  faBan,
  faBars,
  faBell,
  faBuilding,
  faCalculator,
  faCalendarAlt,
  faCaretDown,
  faCaretUp,
  faChartLine,
  faChalkboard,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCity,
  faClock,
  faCog,
  faComment,
  faComments,
  faCommentCheck,
  faCopy,
  faCreditCard,
  faCube,
  faEdit,
  faDownload,
  faEllipsisHAlt,
  faEnvelope,
  faEnvelopeOpen,
  faExclamationCircle,
  faExternalLink,
  faEye,
  faFilter,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileImage,
  faFileImport,
  faFileInvoice,
  faFilePdf,
  faFilePen,
  faFilePlus,
  faFiles,
  faFileSignature,
  faFileSlash,
  faFileUpload,
  faFolder,
  faFolderOpen,
  faFolderPlus,
  faGlobeAmericas,
  faGraduationCap,
  faHammer,
  faHardHat,
  faHistory,
  faIdCard,
  faInbox,
  faInboxes,
  faInboxIn,
  faInboxOut,
  faLifeRing,
  faListCheck,
  faLock,
  faLongArrowLeft,
  faLongArrowRight,
  faMailbox,
  faMapMarkedAlt,
  faMessagesQuestion,
  faMousePointer,
  faNetworkWired,
  faNewspaper,
  faObjectGroup,
  faPaperPlane,
  faPennant,
  faPencil,
  faPlus,
  faPlusCircle,
  faQuestionCircle,
  faReceipt,
  faRocket,
  faRulerTriangle,
  faSearch,
  faShareSquare,
  faSpinner,
  faSquare,
  faSquareCheck,
  faSquareMinus,
  faSquarePlus,
  faStar,
  faStickyNote,
  faSync,
  faSyncAlt,
  faTableCellsLarge,
  faThumbsDown,
  faThumbsUp,
  faThumbTack,
  faTimes,
  faTimesCircle,
  faTimesOctagon,
  faTools,
  faTrafficCone,
  faTrashAlt,
  faTrashRestore,
  faUndo,
  faUpload,
  faUser,
  faUsers,
} from "@fortawesome/pro-light-svg-icons";

import {
  faAddressCard as fasAddressCard,
  faAngleDown as fasAngleDown,
  faAngleLeft as fasAngleLeft,
  faAngleRight as fasAngleRight,
  faAngleUp as fasAngleUp,
  faArrowLeft as fasArrowLeft,
  faArrowRight as fasArrowRight,
  faArrowsAlt as fasArrowsAlt,
  faArrowTurnDownLeft as fasArrowTurnDownLeft,
  faArrowUp as fasArrowUp,
  faArrowUpToLine as fasArrowUpToLine,
  faAsterisk as fasAsterisk,
  faBuilding as fasBuilding,
  faCaretUp as fasCaretUp,
  faCaretDown as fasCaretDown,
  faCaretLeft as fasCaretLeft,
  faCaretRight as fasCaretRight,
  faChartLine as fasChartLine,
  faCircleInfo as fasCircleInfo,
  faCircleQuestion as fasCircleQuestion,
  faCity as fasCity,
  faComment as fasComment,
  faCopy as fasCopy,
  faDiagramSankey as fasDiagramSankey,
  faEnvelope as fasEnvelope,
  faEye as fasEye,
  faFiles as fasFiles,
  faFilePdf as fasFilePdf,
  faFilePen as fasFilePen,
  faFilePlus as fasFilePlus,
  faFileSlash as fasFileSlash,
  faInboxes as fasInboxes,
  faInboxIn as fasInboxIn,
  faListCheck as fasListCheck,
  faLocationDot as fasLocationDot,
  faLock as fasLock,
  faLockOpen as fasLockOpen,
  faMailbox as fasMailbox,
  faMessageQuestion as fasMessageQuestion,
  faMessagesQuestion as fasMessagesQuestion,
  faPenToSquare as fasPenToSquare,
  faRotate as fasRotate,
  faSort as fasSort,
  faThumbsDown as fasThumbsDown,
  faThumbsUp as fasThumbsUp,
  faThumbTack as fasThumbTack,
  faTrashAlt as fasTrashAlt,
  faTriangleExclamation as fasTriangleExclamation,
} from "@fortawesome/pro-solid-svg-icons";

library.add(
  faAcorn,
  faAngleLeft,
  faAngleRight,
  faArchive,
  faArrowDown,
  faArrowLeft,
  faArrowAltRight,
  faArrowRight,
  faArrowAltUp,
  faArrowsAlt,
  faArrowTurnDownLeft,
  faArrowUp,
  faArrowsV,
  faBallotCheck,
  faBan,
  faBars,
  faBell,
  faBuilding,
  faCalculator,
  faCalendarAlt,
  faCaretDown,
  faCaretUp,
  faChartLine,
  faChalkboard,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCity,
  faClock,
  faCog,
  faComment,
  faComments,
  faCommentCheck,
  faCopy,
  faCreditCard,
  faCube,
  faEdit,
  faDownload,
  faEllipsisHAlt,
  faEnvelope,
  faEnvelopeOpen,
  faExclamationCircle,
  faExternalLink,
  faEye,
  faFilter,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileImage,
  faFileImport,
  faFileInvoice,
  faFilePdf,
  faFilePen,
  faFilePlus,
  faFiles,
  faFileSignature,
  faFileSlash,
  faFileUpload,
  faFolder,
  faFolderOpen,
  faFolderPlus,
  faGlobeAmericas,
  faGraduationCap,
  faHammer,
  faHardHat,
  faHistory,
  faIdCard,
  faInbox,
  faInboxes,
  faInboxIn,
  faInboxOut,
  faLifeRing,
  faListCheck,
  faLock,
  faLongArrowLeft,
  faLongArrowRight,
  faMailbox,
  faMapMarkedAlt,
  faMessagesQuestion,
  faMousePointer,
  faNetworkWired,
  faNewspaper,
  faObjectGroup,
  faPaperPlane,
  faPennant,
  faPencil,
  faPlus,
  faPlusCircle,
  faQuestionCircle,
  faReceipt,
  faRocket,
  faRulerTriangle,
  faSearch,
  faShareSquare,
  faSpinner,
  faSquare,
  faSquareCheck,
  faSquareMinus,
  faSquarePlus,
  faStar,
  faStickyNote,
  faSync,
  faSyncAlt,
  faTableCellsLarge,
  faThumbsDown,
  faThumbsUp,
  faThumbTack,
  faTimes,
  faTimesCircle,
  faTimesOctagon,
  faTools,
  faTrafficCone,
  faTrashAlt,
  faTrashRestore,
  faUndo,
  faUpload,
  faUser,
  faUsers,
  fasAddressCard,
  fasAngleDown,
  fasAngleLeft,
  fasAngleRight,
  fasAngleUp,
  fasArrowLeft,
  fasArrowRight,
  fasArrowsAlt,
  fasArrowTurnDownLeft,
  fasArrowUp,
  fasArrowUpToLine,
  fasAsterisk,
  fasBuilding,
  fasCaretUp,
  fasCaretDown,
  fasCaretLeft,
  fasCaretRight,
  fasChartLine,
  fasCircleInfo,
  fasCircleQuestion,
  fasCity,
  fasComment,
  fasCopy,
  fasDiagramSankey,
  fasEnvelope,
  fasEye,
  fasFiles,
  fasFilePdf,
  fasFilePen,
  fasFilePlus,
  fasFileSlash,
  fasInboxes,
  fasInboxIn,
  fasListCheck,
  fasLocationDot,
  fasLock,
  fasLockOpen,
  fasMailbox,
  fasMessageQuestion,
  fasMessagesQuestion,
  fasPenToSquare,
  fasRotate,
  fasSort,
  fasThumbsDown,
  fasThumbsUp,
  fasThumbTack,
  fasTrashAlt,
  fasTriangleExclamation,
);


const customBulmaConfig = {
  ...bulmaConfig,
  iconComponent: 'font-awesome-icon',
  iconPack: 'fal',
  customIconPacks: {
    fal: {
      sizes: {
        default: 'lg',
        'is-small': 'sm',
        'is-medium': 'lg',
        'is-large': '3x',
        'is-extra-large': '5x',
      },
      iconPrefix: '',
    },
    fas: {
      sizes: {
        default: 'lg',
        'is-small': 'sm',
        'is-medium': 'lg',
        'is-large': '3x',
        'is-extra-large': '5x',
      },
      iconPrefix: '',
    },
  },
}


// Intercom

if (import.meta.env.VITE_APP_INTERCOM_APP_ID) {

  window.intercomSettings = {
    app_id: import.meta.env.VITE_APP_INTERCOM_APP_ID,
    hide_default_launcher: true,
    background_color: '#107e67',
    product: 'SubsPortal',
  };

  (function () {
    var w: Window & any = window;
    var ic: any = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      var d = document;
      var i: any = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args: any) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = `https://widget.intercom.io/widget/${import.meta.env.VITE_APP_INTERCOM_APP_ID}`;
        var x: any = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      };
      if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();
}


import '@/assets/scss/app.scss';

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
const vueApp = createApp(App);

vueApp.use(pinia)
vueApp.use(router);
vueApp.use(Oruga, customBulmaConfig)

vueApp.component('font-awesome-icon', FontAwesomeIcon);

vueApp.component('ExternalFileColumn', ExternalFileColumn);
vueApp.component('HTMLColumn', HTMLColumn);
vueApp.component('NameColumn', NameColumn);
vueApp.component('ProjectInvitedColumn', ProjectInvitedColumn);
vueApp.component('ResponseConfirmedColumn', ResponseConfirmedColumn);
vueApp.component('TextColumn', TextColumn);
vueApp.component('TradeColumn', TradeColumn);
vueApp.component('UserNameColumn', UserNameColumn);

vueApp.mount("#app");
