import Projects from "@/pages/Projects/Index.vue";

export default [
  {
    name: 'overview',
    path: '/',
    component: Projects,
    meta: {
      requiresAuth: true,
      title: 'Current Projects',
    }
  },
]
