<template>
    <div class="project-invited-column">
        <div v-if="openInvitation">Open</div>
        <div v-if="value">Invited</div>
    </div>
</template>

<script setup lang="ts">

import {computed, toRefs} from "vue";

const props = withDefaults(defineProps<{
  row: any,
  value: boolean,
}>(),  {
});
const {
  row,
  value,
} = toRefs(props);

const openInvitation = computed(() => row.value.open_invitation);

</script>

<style scoped lang="scss">
    .project-invited-column {
      max-width: 200px;
      overflow-x: auto;
    }
</style>