<template>
    <div>
        <div class="title">Search</div>

        <input id="search-input"
               type="text"
               class="input"
               ref="searchInput"
               placeholder="Search for..."
               @keypress.enter="handleSearch"
               @keydown.esc="handleClearSearch"
               v-model="term"
               autocomplete="false"
        />

        <div class="results-model" v-for="item in resultModelsWithItems" :key="item.key">
            <label class="label" v-if="searchResults[item.key]">{{item.title}}</label>
            <ul>
                <template v-if="item.models && item.models.length">
                    <li v-for="model in item.models" :key="model.id">
                        <router-link :to="item.route(model)">
                            {{ model[item.labelKey] }}
                        </router-link>
                    </li>
                </template>
                <template v-else-if="searchResults[item.key] && searchResults[item.key].length === 0">
                    <li>No search results</li>
                </template>
            </ul>
            <p class="help" v-if="searchResults[item.key] && searchResults[item.key].length > limit">
                Plus {{ searchResults[item.key].length - limit }} additional results.
            </p>
        </div>
    </div>
</template>

<script setup lang="ts">

import {
  findRouteByName,
  NotificationError,
} from '@/internal';

import {computed, onMounted, ref} from "vue";
import {useSystemStore} from "@/stores/SystemStore";

const systemStore = useSystemStore();
const limit = ref(10);
const searchInput = ref<HTMLElement | null>(null);
const term = ref('');
const resultModels = ref([
  {
    title: 'Projects',
    key: 'projects',
    labelKey: 'name',
    route: (model: any) => {
      return {
        ...findRouteByName('projects/show'),
        params: {
          projectId: model.id,
        },
      };
    },
  },
]);

onMounted(() => {
  if (searchInput.value) {
    searchInput.value.focus();
  }
});

const resultModelsWithItems = computed(() => {
  return resultModels.value.map(item => {
    const models = searchResults.value[item.key] || [];
    return {
      ...item,
      models: models.slice(0, 5),
    };
  });
})

const searchResults = computed(() => systemStore.searchResults || []);

function handleSearch() {
  systemStore.search({
    params: {
      q: term.value,
      include: [
        'projects',
      ],
    }
  }).then(() => {
  }).catch((error) => {
    if (error.errors) {
      NotificationError({}, error);
    }
    else {
      NotificationError({
        message: error,
      });
    }
  });
}

function handleClearSearch() {
  term.value = '';
}

</script>

<style scoped lang="scss">
    #search-input {
        border-bottom-width: 2px;
        border-left: none;
        border-right: none;
        border-radius: 0;
        border-top: none;
        box-shadow: none;

        &:active {
            border-bottom-color: $primary;
        }
    }

    .results-model {
        margin: 20px 0;
    }
</style>