import type {DocumentObject} from 'jsonapi-fractal';
import {BaseApi} from 'toolbox';
import Auth from "@/auth";

export abstract class Api extends BaseApi {
    protected baseUrl = `${import.meta.env.VITE_APP_API_URL}`;
    protected authToken = window.sessionStorage.getItem('access_token') || '';

    async processResponse(response: any) {
        const data = typeof response.json === 'function' ? await response.json() : response.data;

        if (response.ok || response.statusText.toLowerCase() === "ok") {
            return data;
        }
        else if (response.status === 403 || (response.status === 401 && data.message.toLowerCase().indexOf('unauthenticated') >= 0)) {
            Auth.logout();
        }
        else if (response.status === 500) {
            return Promise.reject('Server Error');
        }
        else {
            let error = data && (data.message || data);

            try {
                if (error.errors.length && error.errors[0].message && JSON.parse(error.errors[0].message).hasOwnProperty('errors')) {
                    error = JSON.parse(error.errors[0].message);
                }
                else if (JSON.parse(error).hasOwnProperty('errors')) {
                    error = JSON.parse(error);
                }
            }
            catch (err) {
                error = 'Error Occurred';
            }

            return Promise.reject(error);
        }
    }
}

export type ApiOptions = {
    include: string[],
}

export type LinksObject = {
    self: string;
    first: string;
    last: string;
};

export type MetaObject = {
    pagination: {
        total: number;
        count: number;
        per_page: number;
        current_page: number;
        total_pages: number;
    };
};

export type APIResponse = DocumentObject & {
    links: LinksObject;
    meta: MetaObject;
};

export type ObjectWithMeta<T> = {
    data: T | T[];
    meta: MetaObject;
    links: LinksObject;
};