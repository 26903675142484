<template>
    <nav id="the-breadcrumbs" class="breadcrumb" aria-label="breadcrumbs">
        <ul>
            <template v-if="breadcrumbs.length">
                <li v-for="(breadcrumb, i) in breadcrumbs"
                    :key="`breadcrumb-${i}`"
                    :class="{ 'is-active': breadcrumb.route && breadcrumb.route.name === route.name }"
                >
                    <router-link :to="breadcrumb.route">
                        {{breadcrumb.title}}
                    </router-link>
                </li>
            </template>
            <template v-else>
                <li>&nbsp;</li>
            </template>
        </ul>
    </nav>
</template>

<script setup lang="ts">

  import _ from 'lodash';
  import { ref, computed } from "vue";
  import { useRoute } from "vue-router";
  import { storeToRefs } from 'pinia';
  import {
    findRouteByName,
  } from "@/internal";
  import {useProjectStore} from "@/stores/ProjectStore";

  const route = useRoute();
  const {project} = storeToRefs((useProjectStore()));

  const breadcrumbs = computed(() => {
    const breadcrumbs = (route.meta && route.meta.breadcrumbs) ? route.meta.breadcrumbs : [];

    return breadcrumbs.map((breadcrumb) =>  {
      let title = '';
      let foundRoute = findRouteByName(breadcrumb);

      if (foundRoute && foundRoute.meta) {

        foundRoute.params = {
          ...route.params,
        };

        if (foundRoute.meta.useModelForTitle) {
          const modelName = _.camelCase(foundRoute.meta.useModelForTitle);
          let model = null;

          if (modelName === 'project') {
            model = <Record<string, any>>project.value;
          }

          if (model) {
            const modelTitles = <Record<string, string>>{
              project: 'name',
              formQuestion: 'question',
              formResponse: 'title',
            };

            title = model ? model[modelTitles[modelName]] : '';
          } else {
            title = '';
          }
        }
        else if (foundRoute.meta.title) {
          title = foundRoute.meta.title;
        }
      }
      else {
        return false;
      }

      return {
        title,
        route: foundRoute,
      };
    }).filter(breadcrumb => breadcrumb !== false);
  });

</script>

<style scoped lang="scss">
    #the-breadcrumbs {

    }
</style>
