import {z} from "zod";
import {
    utcToLocal,
} from "@/internal";
import {UserSchema} from "@/models/User";
import {ProjectContactSchema} from "@/models/ProjectContact";
import {NoticeSchema} from "@/models/Notice";
import {OPRFileSchema} from "@/models/OPRFile";

const dateRegex = new RegExp("^\\d{4}-\\d{2}-\\d{2}$");
const timeRegex = new RegExp("^[0-2][0-9](:[0-5][0-9]){2}$");


export const BidPackageSchema = z.object({
    id: z.coerce.number(),
    project_id: z.coerce.number(),
    name: z.string().optional(),
    address: z.string().optional().nullable(),
    city: z.string().optional().nullable(),
    state: z.string().optional().nullable(),
    zip: z.string().optional().nullable(),
    location: z.string().optional().nullable(),
    scope: z.string().optional().nullable(),
    exclusions: z.string().optional().nullable(),
    other_info: z.string().optional().nullable(),
    is_bidding: z.coerce.number().default(0),
    is_bid_uploaded: z.coerce.boolean().default(false),

    sub_bid_date: z.string().regex(dateRegex).transform((val:string) => utcToLocal(val)).nullable(),
    sub_bid_time: z.string().regex(timeRegex).transform((val:string) => utcToLocal(val)).nullable(),
    sub_bid_due: z.string().optional().nullable(),

    site_meeting_date: z.string().regex(dateRegex).transform((val:string) => utcToLocal(val)).nullable(),
    site_meeting_time: z.string().regex(timeRegex).transform((val:string) => utcToLocal(val)).nullable(),
    site_meeting_at: z.string().nullable(),

    created_at: z.string().datetime().transform<string>(v => utcToLocal(v)).nullable(),

    estimators: z.array(UserSchema).optional().default([]),
    notices: z.array(NoticeSchema).optional().default([]),
    project_contacts: z.array(ProjectContactSchema).optional().default([]),
});

export type BidPackage = z.infer<typeof BidPackageSchema>;