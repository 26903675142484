import {defineStore} from "pinia";
import { StorageSerializers, useStorage } from '@vueuse/core';
import {ref} from "vue";
import InviteAPI from "@/api/InviteAPI";

export const useInviteStore = defineStore('invite', () => {
  const invites = useStorage('invites', [], sessionStorage, {
    serializer: StorageSerializers.object,
  });
  const isLoading = ref<boolean>(false);

  const clearInvites = () => {
    invites.value = null;
  };

  const index = async (projectId: number, payload: any) => {
    const { params } = payload;

    isLoading.value = true;
    clearInvites();
    await new InviteAPI().index(projectId, params)
        .then((data: any) => {
          invites.value = data.invites;
        })
        .finally(() => isLoading.value = false);
  }

  return {
    invites,
    isLoading,
    index,
  };
});
