import {defineStore} from "pinia";
import {StorageSerializers, useStorage} from "@vueuse/core";
import {ref} from "vue";
import ProjectRFIAPI from "@/api/ProjectRFIAPI";

export const useProjectRFIStore = defineStore('project-rfi', () => {

  const projectRFI = useStorage('project-rfi', null, sessionStorage, {
    serializer: StorageSerializers.object,
  });
  const isLoading = ref<boolean>(false);

  const clear = () => {
    projectRFI.value = null;
  };

  const store = async (projectId: number, payload: any = { params: null }) => {
    const { params, ...rest } = payload;

    isLoading.value = true;
    return await new ProjectRFIAPI().store(projectId, rest)
        .then(data => {
          return data;
        })
        .finally(() => isLoading.value = false);
  };

  return {
    isLoading,
    store,
  };
});
