<template>
    <div v-html="sanitized">
    </div>
</template>

<script setup lang="ts">

  import DOMPurify from 'dompurify';
  import {computed, toRefs} from "vue";

  const props = withDefaults(defineProps<{
    value: string | undefined | null,
    }>(), {
  });
  const {
    value,
  } = toRefs(props);

  const sanitized = computed(() => {
    return DOMPurify.sanitize(value.value);
  });

</script>

<style scoped lang="scss">
</style>