import {Api} from '@/api/Api';
import {deserialize} from './Deserializer';
import {type ProjectRFI, ProjectRFISchema} from "@/models/ProjectRFI";

export default class ProjectRFIAPI extends Api {

  store(projectId: number, data: any) {
    return this.post({
      url: `projects/${projectId}/rfis`,
      headers: [],
      body: JSON.stringify(data),
    })
        .then((res) => this.processResponse(res))
        .then(res => deserialize<ProjectRFI>(res, ProjectRFISchema) as ProjectRFI);
  }
};
