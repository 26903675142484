import { MenuItem } from '@/internal';

export default class Menu {

  title: string;
  allMenuItems: MenuItem[];
  menuItems: Record<any, any>[];

  constructor(config: any = {}) {
    this.title = config.title || '';
    this.allMenuItems = config.menuItems ? config.menuItems.map((menuItem: object) => new MenuItem(menuItem)) : [];
    this.menuItems = this.allMenuItems.filter((menuItem: MenuItem) => menuItem.when === true || menuItem.when === null);
  }
}