import {
  findRouteByName,
  Menu,
} from '@/internal';

interface Payload {
  handleTakeOffSoftwareClick: Function,
}

export default (payload: Payload) => {

  const {
    handleTakeOffSoftwareClick,
  } = payload;

  return new Menu({
    menuItems: [
      {
        title: 'Projects',
        route: findRouteByName('projects'),
        icon: 'inboxes',
        iconPack: 'fal',
        menuItems: [
          {
            route: {
              ...findRouteByName('projects/current'),
              params: {
              },
            },
            title: 'Current Projects',
            icon: 'inbox-in',
            iconPack: 'fal',
            activeWithParent: true,
          },
          {
            route: {
              ...findRouteByName('projects/past'),
              params: {
              },
            },
            title: 'Past Projects',
            icon: 'inbox-out',
            iconPack: 'fal',
          },
        ],
      },
      {
        title: 'Take-off Software',
        onClick: handleTakeOffSoftwareClick,
        icon: 'calculator',
        iconPack: 'fal',
      },
      {
        title: 'Update My Info',
        route: findRouteByName('company/edit'),
        icon: 'user',
        iconPack: 'fal',
      },
    ],
  });
};
