<template>
  <div class="response-confirmed-column">
    {{confirmedResponseLabel}}
  </div>
</template>

<script setup lang="ts">

import {computed, toRefs} from "vue";

  const props = withDefaults(defineProps<{
    row: any,
    value: number | string,
  }>(), {
  });
  const {
    row,
    value,
  } = toRefs(props);

  const emit = defineEmits<{
    'click': [any],
  }>();

  const confirmedResponseLabel = computed(() => {
    if (parseInt(value.value) === 0) {
      return 'Unconfirmed';
    }
    else if (parseInt(value.value) === 1) {
      return 'Yes';
    }
    else if (parseInt(value.value) === 2) {
      return 'No';
    }
    else if (parseInt(value.value) === 3) {
      return 'Unsure';
    }
  })

</script>

<style scoped lang="scss">
    .response-confirmed-column {
      max-width: 200px;
    }
</style>