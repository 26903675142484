<template>
    <div class="user-name-column no-wrap" v-if="value">
        {{ `${value.first_name} ${value.last_name}` }}
    </div>
</template>

<script setup lang="ts">

import {toRefs} from "vue";

interface User {
  first_name: string,
  last_name: string,
}
const props = withDefaults(defineProps<{
  row: any,
  value: User,
}>(), {
});
const {
  row,
  value,
} = toRefs(props);

</script>

<style scoped lang="scss">
    .user-name-column {
        align-items: center;
        display: flex;
    }
</style>