<template>
    <div>
        <NavMenu :menu="recentMenu"  />
    </div>
</template>

<script setup lang="ts">

import NavMenu from '../NavMenu.vue';

import {computed, toRefs} from "vue";

import {
  Menu,
} from "@/internal";

const props = withDefaults(defineProps<{
  recents: any[],
}>(), {
});
const {
  recents,
} = toRefs(props);

const recentMenu = computed<Menu>(() => {
  return new Menu({
    title: 'Recent',
    menuItems: recents.value.map(route => {
      return {
        title: route.meta?.title || route.meta.title || route.title || 'No Title Set',
        route,
        displayInactive: true,
      };
    }),
  });
});

</script>

<style scoped lang="scss">

</style>