<template>
  <div id="company-files" class="is-full-height">

    <IndexTable
        :columns="defaultTableColumns"
        default-status-filter="active"
        :display-status-filter="false"
        :items="files"
        resource="opr-file"
        title="Submitted Bids"
        @click="handleShow"
    />

  </div>
</template>

<script setup lang="ts">

import {computed, onMounted, ref, toRefs} from "vue";
import {storeToRefs} from "pinia";
import IndexTable from "@/components/IndexTable.vue";
import {useBidPackageStore} from "@/stores/BidPackageStore";
import {useProjectStore} from "@/stores/ProjectStore";
import {useBidPackageCompanyFileStore} from "@/stores/BidPackageCompanyFileStore";

const { bidPackage } = storeToRefs(useBidPackageStore());
const { project } = storeToRefs(useProjectStore());
const { bidPackageCompanyFiles } = storeToRefs(useBidPackageCompanyFileStore());
const defaultTableColumns = [
  {
    field: 'id',
    label: 'ID',
    width: 40,
    numeric: true,
  },
  {
    field: 'name',
    label: 'File',
    component: 'ExternalFileColumn',
    sortable: true,
  },
  {
    field: 'trade',
    label: 'Trade',
    component: 'TradeColumn',
    sortable: true,
  },
  {
    field: 'description',
    label: 'Comment',
    component: 'HTMLColumn',
  },
  {
    field: 'uploaded_at',
    label: 'Uploaded At',
    sortable: true,
  },
];

const props = withDefaults(defineProps<{
  bidPackageId: number | null,
}>(), {
  bidPackageId: null,
});
const {
  bidPackageId,
} = toRefs(props);

const files = computed(() => bidPackageCompanyFiles.value || []);

function handleShow(file: any) {
  window.open(file.file_path,'_blank');
}

</script>

<style lang="scss" scoped>

#company-files {
}

</style>

<style lang="scss">

#company-files {
  a {
    border: 0;
  }

}

</style>