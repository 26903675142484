<template>
    <div class="html-column">
      <HTMLText :value="value" />
    </div>
</template>

<script setup lang="ts">

import HTMLText from '@/components/HTMLText.vue';
import {toRefs} from "vue";

const props = withDefaults(defineProps<{
  row: any,
  value: any,
}>(), {
});
const {
  row,
  value,
} = toRefs(props);

</script>

<style scoped lang="scss">
    .html-column {
      display: flex;
      max-width: 300px;
      min-width: 250px;
      white-space: break-spaces;
    }
</style>