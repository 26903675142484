<template>
  <div id="bidding-tab" class="tab">
    <o-loading
        :full-page="false"
        :active="isLoading"
        icon="rotate"
        icon-size="large"
    />

    <div class="info is-flex is-full-height">
      <div class="column has-margin-right is-half-desktop is-one-third-fullhd is-one-third-widescreen is-full-tablet is-full-mobile" v-if="!publicProjectView">
        <div class="label">Invited to Bid On</div>
        <div class="box overflow-scroll is-full-height">
          <div class="overflow-wrap">{{company?.primary_contact?.first_name}} {{company?.primary_contact?.last_name}} with {{company?.name}}</div>
          <div class="has-margin-top-large overflow-wrap" v-if="hasUnanswered">Will you be bidding?</div>
          <div class="box trade has-margin-top-large" v-for="bidder in bidders" :class="{'error': error(bidder.id, 'confirmed')}">
            <div class="label" v-if="!bidder.trade && bidPackageId">{{ bidPackage?.name }}</div>
            <div class="label" v-else-if="bidder.trade">
              <div>{{ bidder.trade.division_name }}</div>
              <div>{{ bidder.trade.short_label }}</div>
            </div>
            <div class="label" v-else-if="project">{{ project.name }}</div>

            <o-field :variant="error(bidder.id, 'confirmed') ? 'danger' : ''"
                     :message="error(bidder.id, 'confirmed')"
            >
            <div class="is-flex is-flex-direction-column has-margin-top-large">
                <o-radio v-model="answers[bidder.id]" native-value="1" class="has-margin-bottom-large" @update:modelValue="handleUpdate(bidder.id)">
                  <o-icon icon="thumbs-up" pack="fas" size="small" variant="success" /> Yes
                </o-radio>
                <o-radio v-model="answers[bidder.id]" native-value="2" class="has-margin-bottom-large" @update:modelValue="handleUpdate(bidder.id)">
                  <o-icon icon="thumbs-down" pack="fas" size="small" variant="danger" /> No
                </o-radio>
                <o-radio v-model="answers[bidder.id]" native-value="3" @update:modelValue="handleUpdate(bidder.id)">
                  <o-icon icon="circle-question" pack="fas" size="small" variant="warning" /> Unsure
                </o-radio>
            </div>
            </o-field>
          </div>
        </div>
      </div>

      <div class="column is-full-tablet is-full-mobile" :class="{'is-half-desktop is-two-thirds-fullhd is-two-thirds-widescreen': !publicProjectView, 'is-full-desktop is-full-thirds-fullhd is-tull-thirds-widescreen': publicProjectView}">
        <div class="label">{{ showFiles && hasFiles ? 'Project Files' : 'Plans' }}</div>
        <div class="box overflow-scroll is-full-height">
          <div v-if="showFiles && hasFiles">
            <FileTree :files="files"
                      :download-all-files="downloadFiles"
                      :download-selected-files="downloadSelectedFiles"
                      :default-expanded-state="true"
            />
          </div>
          <div v-else class="overflow-wrap">
            <HTMLText :value="project?.exclusions || 'None'" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

import {
  NotificationSuccess,
  NotificationError,
} from "@/internal";
import {computed, onMounted, ref, toRefs, watch} from "vue";
import {storeToRefs} from "pinia";
import {FileTree} from 'ps-ui';
import type {ProjectBidder} from "@/models/ProjectBidder";
import {number} from "zod";
import HTMLText from "@/components/HTMLText.vue";
import {useBidPackageProjectBidderStore} from "@/stores/BidPackageProjectBidderStore";
import {useCompanyStore} from "@/stores/CompanyStore";
import {useProjectStore} from "@/stores/ProjectStore";
import {useBidPackageStore} from "@/stores/BidPackageStore";
import {useBidPackageFileStore} from "@/stores/BidPackageFileStore";

const bidPackageProjectBidderStore = useBidPackageProjectBidderStore();
const bidPackageFileStore = useBidPackageFileStore();
const { bidPackageProjectBidders } = storeToRefs(useBidPackageProjectBidderStore());
const { bidPackageFiles } = storeToRefs(useBidPackageFileStore());
const { bidPackage } = storeToRefs(useBidPackageStore());
const { company } = storeToRefs(useCompanyStore());
const errors = ref(<Record<any, any>>[]);
const { project } = storeToRefs(useProjectStore());
const answers = ref<Record<any, number>>({});
const awarded = ref<boolean>(false);

const props = withDefaults(defineProps<{
  bidPackageId: number | null,
  publicProjectView?: boolean,
}>(), {
  bidPackageId: null,
  publicProjectView: false,
});
const {
  bidPackageId,
  publicProjectView,
} = toRefs(props);

const emit = defineEmits<{
  'projectBidderUpdated': [],
}>();

const bidders = computed<ProjectBidder[]>(() => {
  const trades: (number | null)[] = [];
  answers.value = {};
  awarded.value = false;
  return bidPackageProjectBidders.value?.filter((bidder: ProjectBidder) => {
    if (bidder.bid_awarded) {
      awarded.value = true;
    }

    if ((bidder.trade && trades.includes(bidder.trade.id)) || (!bidder.trade && trades.includes(null))) {
      return false;
    }
    else {
      answers.value[bidder.id] = bidder.confirmed;
      trades.push(bidder.trade ? bidder.trade.id : null);
      return true;
    }
  });
});

const files = computed(() => bidPackageFiles.value || []);

const hasFiles = computed(() => !!files.value.length);

const hasUnanswered = computed(() => !!bidPackageProjectBidders.value?.filter((bidder: ProjectBidder) => bidder.confirmed === 0));

const isLoading = computed(() => bidPackageProjectBidderStore.isLoading || bidPackageFileStore.isLoading);

const showFiles = computed(() => (!publicProjectView.value && project.value?.limit_opr_to_awarded && awarded.value) || !project.value?.limit_opr_to_awarded);

function clearErrors() {
  errors.value = [];
}

function downloadSelectedFiles(selectedFiles: any) {
  if (!selectedFiles || !selectedFiles.length) {
    NotificationError({message: "Select Files to Download"});
  }
  else {
    const selectedIds = selectedFiles.map((file: any) => file.id);
    downloadFiles(selectedIds);
  }
}

function downloadFiles(selectedFiles: number[]|null) {
  if (!project.value || bidPackageId.value === null) {
    return;
  }

  const payload: any = {
    files: selectedFiles,
  };

  bidPackageFileStore.zip(project.value.id, bidPackageId.value, payload)
    .catch(error => {
      if (error.errors) {
        NotificationError({}, error);
      } else {
        NotificationError({
          message: error,
        });
      }
    });
}

function error(projectBidderId: number | string, key: string) {
  return errors.value?.find((error: any) => error.hasOwnProperty('key') && (parseInt(error.forId) === <number>projectBidderId) && (error.key === key))?.detail || '';
}

function handleUpdate(projectBidderId: number) {
  updateProjectBidder(projectBidderId);
}

function updateProjectBidder(projectBidderId: number) {

  const payload: any = {
    confirmed: answers.value[projectBidderId],
  };

  bidPackageProjectBidderStore.update(projectBidderId, <number>project.value?.id, <number>bidPackageId.value, payload)
    .then(() => {
      NotificationSuccess({
        message: `Response Updated`,
      });

      emit('projectBidderUpdated');
    }).catch((error: any) => {
      if (error.hasOwnProperty('errors')) {
        errors.value = error.errors.map((error: any) => {
          if (error.key === 'confirmed') {
            return {
              ...error,
              forId: projectBidderId,
            }
          }

          return error;
        });
      }
      else {
        NotificationError({
          message: error,
        });
      }
    });
}

</script>

<style lang="scss" scoped>

#bidding-tab {
  height: 100%;

  .box {
    &.error {
      border-color: $danger;
    }

    &.trade {
      overflow-x: auto;
      text-wrap: wrap;
      background-color: $grey-lightest2;
    }

    &.overflow-scroll {
      overflow-x: auto;
    }

    .overflow-wrap {
      white-space: break-spaces;
    }

    .ps-file-tree {
      padding: 0;
    }
  }
}

@media (max-width: 1023px) {
  .info {
    flex-direction: column;
  }
}

</style>

<style lang="scss">

#bidding-tab {

  a {
    border: unset;
    padding: unset;
    justify-content: unset;
  }

  ul {
    border: unset;
  }

  .file-tree-tools {
    padding-top: 0 !important;
  }
}

</style>