import { z } from "zod";

export const UserSchema = z.object({
    id: z.coerce.number().optional(),
    first_name: z.string().optional().nullable(),
    last_name: z.string().optional().nullable(),
    name: z.string().optional().nullable(),
    company_name: z.string().optional().nullable(),
    phone_number: z.string().optional().nullable(),
    extension: z.string().optional().nullable(),
    fax_number: z.string().optional().nullable(),
    email: z.string().optional().nullable(),
});

export type User = z.infer<typeof UserSchema>;