<template>
  <Page>Loading...</Page>
</template>

<script setup lang="ts">

  import { onBeforeMount } from "vue";
  import { useRouter } from "vue-router";

  import Page from "@/components/Page.vue";
  import Auth from "@/auth";
  import {
    findRouteByName,
  } from "@/internal";

  onBeforeMount(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const projectId = urlParams.get('project_id');

    if (Auth.isAuthenticated()) {
      const router = useRouter();

      if (projectId) {
        window.sessionStorage.setItem("project_id", projectId);

        const route = findRouteByName('projects/show');
        route.params = {
          ...route.params,
          projectId: projectId,
        };

        router.push(route);
      }
      else {
        router.push({
          name: 'projects'
        });
      }
    } else {
      Auth.authenticate();
    }
  });

</script>
