<template>
  <Page :title="pageTitle" id="project">
    <o-loading
        :full-page="true"
        :active="isLoading"
        icon="rotate"
        icon-size="large"
    />

    <div class="is-flex" v-if="!isLoading && project">
      <div class="tabs">
        <Tabs :bid-package-id="null" :public-project-view="true" />
      </div>
    </div>

  </Page>
</template>

<script setup lang="ts">

import Page from "@/components/Page.vue";
import {NotificationError} from "@/internal";
import {usePublicProjectStore} from "@/stores/PublicProjectStore";
import {storeToRefs} from "pinia";
import {computed, onMounted} from "vue";
import {useRoute} from "vue-router";
import Tabs from "@/pages/Projects/Tabs.vue";

const publicProjectStore = usePublicProjectStore();
const {project, isLoading} = storeToRefs(usePublicProjectStore());
const route = useRoute();

const pageTitle = computed(() => {
  return project.value ? project.value.name : '';
});

const projectId = computed(() => parseInt(route.params.projectId as string));

onMounted(() => {
  loadProject();
});

function loadProject() {
  const payload = {
    params: {
      include: [
        'client',
        'companyFiles',
        'estimators',
        'notices',
        'projectContacts',
      ],
    }
  };

  publicProjectStore.show(projectId.value, payload)
      .catch(error => {
        if (error.errors) {
          NotificationError({}, error);
        } else {
          NotificationError({
            message: error,
          });
        }
      });
}

</script>

<style lang="scss" scoped>

#project {
  .tabs {
    width: 100%;
  }
}

</style>