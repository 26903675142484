import { z } from "zod";

import {ContactUpdateSchema} from "@/models/ContactUpdate";
import {utcToLocal} from "@/internal";

export const CompanyUpdateSchema = z.object({
    id: z.coerce.number(),
    name: z.string().optional(),
    address: z.string().optional().nullable(),
    address2: z.string().optional().nullable(),
    city: z.string().optional().nullable(),
    state: z.string().optional().nullable(),
    zip: z.string().optional().nullable(),
    country: z.string().optional().nullable(),
    submitted_on: z.string().transform((val:string) => utcToLocal(val)).nullable(),

    contact_update: ContactUpdateSchema.optional().default(ContactUpdateSchema.parse({})),
});


export type CompanyUpdate = z.infer<typeof CompanyUpdateSchema>;