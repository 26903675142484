import {
  findRouteByName,
  Menu,
  userHasPermission,
} from '@/internal';

interface Payload {

}

export default (payload: Payload = {}) => {

  return new Menu({
    menuItems: [
      {
        hideTitle: true,
        route: findRouteByName('projects'),
      },
    ],
  });
};