import { useProgrammatic } from '@oruga-ui/oruga-next';

const {oruga} = useProgrammatic();

export default function NotificationSuccess(config: any) {
  oruga.notification.open({
    position: 'top-right',
    variant: 'success',
    duration: 5000,
    ...config,
  });
};
