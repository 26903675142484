import { z } from "zod";

export const TradeSchema = z.object({
    id: z.coerce.number(),
    business_unit_id: z.string().optional().nullable(),
    csi: z.string().optional().nullable(),
    number: z.string().optional().nullable(),
    name: z.string().optional().nullable(),
    division_name: z.string().optional().nullable(),
    full_label: z.string().optional().nullable(),
    short_label: z.string().optional().nullable(),
});

export type Trade = z.infer<typeof TradeSchema>;