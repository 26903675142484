<template>
    <div @click="handleClick" class="text-column is-link">
        {{value}}
    </div>
</template>

<script setup lang="ts">

  import {toRefs} from "vue";

  const props = withDefaults(defineProps<{
    row: any,
    value: string,
  }>(),  {
  });
  const {
    row,
    value,
  } = toRefs(props);

  const emit = defineEmits<{
    'click': [any],
  }>();

  function handleClick() {
    emit('click', row.value);
  }

</script>

<style scoped lang="scss">
    .text-column {
      max-width: 200px;
      overflow-x: auto;
    }
</style>