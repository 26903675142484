<template>
    <div class="the-page">
        <header>
            <Breadcrumbs />
            <div class="columns">
                <div class="column is-two-thirds">
                    <h1 class="title" v-text="pageTitle" v-if="pageTitle" />
                    <h2 class="subtitle is-6"><slot name="subtitle"/></h2>
                    <slot name="addon" />
                </div>
                <div class="column has-text-right">
                    <slot name="controls" />
                </div>
            </div>
        </header>
        <div class="page-container">
            <slot />
        </div>
        <div class="page-footer">
            <slot name="footer" />
        </div>
    </div>
</template>

<script setup lang="ts">

  import { ref, computed } from "vue";
  import { useRoute } from "vue-router";

  import Breadcrumbs from "@/components/Breadcrumbs.vue";

  const route = useRoute();

  const props = withDefaults(defineProps<{
    fullWidth?: boolean,
    narrow?:  boolean,
    title?: string,
  }>(), {
    fullWidth: false,
    narrow:  false,
    title: '',
  });

  const pageTitle = computed(() => {
    return props.title || route.meta.title;
  });

</script>
