import {defineStore} from "pinia";
import {type RemovableRef, StorageSerializers, useStorage} from "@vueuse/core";
import {ref} from "vue";
import type {Company} from "@/models/Company";
import BidPackageCompanyFileAPI from "@/api/BidPackageCompanyFileAPI";

export const useBidPackageCompanyFileStore = defineStore('bid-package-company-files', () => {

  const bidPackageCompanyFiles = useStorage('bidPackageCompanyFiles', null, sessionStorage, {
    serializer: StorageSerializers.object,
  });
  const isLoading = ref<boolean>(false);

  const clear = () => {
    bidPackageCompanyFiles.value = null;
  };

  const index = async (projectId: number, bidPackageId: number, payload: any = { params: null}) => {
    const { params } = payload;

    isLoading.value = true;
    clear();
    await new BidPackageCompanyFileAPI().index(projectId, bidPackageId, params)
      .then(data => {
        bidPackageCompanyFiles.value = data;
      })
      .finally(() => isLoading.value = false);
  };

  const store = async (projectId: number, bidPackageId: number, data: any) => {

    isLoading.value = true;
    return await new BidPackageCompanyFileAPI().store(projectId, bidPackageId, data)
        .then(data => {
          return data;
        })
        .finally(() => isLoading.value = false);
  };

  return {
    bidPackageCompanyFiles,
    isLoading,
    index,
    store,
  };
});
