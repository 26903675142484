import {defineStore} from "pinia";
import SystemAPI from "@/api/SystemAPI";
import {StorageSerializers, useStorage} from "@vueuse/core";
import {ref} from "vue";

export const useSystemStore = defineStore('system', () => {
  const navPanel = useStorage<string>('navPanel', '', sessionStorage, {
    serializer: StorageSerializers.object,
  });
  const navPanelOpen = useStorage<boolean>('navPanelOpen', false, sessionStorage, {
    serializer: StorageSerializers.object,
  });
  const searchResults = useStorage<any>('searchResults', [], sessionStorage, {
    serializer: StorageSerializers.object,
  });
  const isLoading = ref<boolean>(false);

  const clear = () => {
    searchResults.value = null;
  };

  const search = async (payload: any) => {
    const { params } = payload;

    isLoading.value = true;
    clear();
    await new SystemAPI().search(params)
        .then(data => {
          searchResults.value = data;
        })
        .finally(() => isLoading.value = false);
  };

  const toggleNavPanel = (panel: string) => {
    if (navPanelOpen.value) {
      setNavPanel('');
      setTimeout(() => {
        setNavPanel(panel);
      }, 200);
    }
    else {
      setNavPanel(panel);
    }
  };

  const setNavPanel = (panel: string) => {
    navPanel.value = panel || '';
    navPanelOpen.value = panel.length > 0;
  };

  return {
    searchResults,
    isLoading,
    navPanel,
    navPanelOpen,
    search,
    toggleNavPanel,
  };
});
