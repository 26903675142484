import {
  findRouteByName,
  Menu,
  userHasPermission,
} from '@/internal';

interface Payload {
  handleAccountClick: Function,
  handleHelpClick: Function,
  handleSystemPreferencesClick: Function,
  handleLogOutClick: Function,
  handleSuperClick: Function,
  hideTitles: boolean,
  numUnreadNotifications: number | null,
  title?: string,
  user: any,
};

export default (payload: Payload) => {

  const {
    handleAccountClick,
    handleHelpClick,
    handleSystemPreferencesClick,
    handleLogOutClick,
    handleSuperClick,
    hideTitles,
    numUnreadNotifications,
    user,
  } = payload;

  const hideTitle: boolean = hideTitles !== undefined ? hideTitles : true;
  const title: string = payload.title || '';

  return new Menu({
    title,
    menuItems: [
      {
        hideTitle,
        icon: 'question-circle',
        onClick: handleHelpClick,
        title: 'Help',
      },
      {
        hideTitle,
        icon: 'user',
        route: findRouteByName('company/edit'),
        title: 'Company',
      },
      {
        hideTitle,
        icon: 'lock',
        onClick: handleLogOutClick,
        title: 'Log Out',
      },
    ]
  });
}