import {createRouter, createWebHistory} from "vue-router";
import AppRoutes from "@/router/AppRoutes";
import AuthRoutes from "@/router/AuthRoutes";
import CompanyRoutes from "@/router/CompanyRoutes";
import ProjectRoutes from "@/router/ProjectRoutes";
import PublicProjectRoutes from "@/router/PublicProjectRoutes";
import Auth from "@/auth";

const router: any = createRouter({
  history: createWebHistory(),
  routes: [
    ...AppRoutes,
    ...AuthRoutes,
    ...CompanyRoutes,
    ...ProjectRoutes,
    ...PublicProjectRoutes,
  ]
});

router.beforeEach((to: any, from: any, next: any) => {

  if(to.meta.requiresAuth && !Auth.isAuthenticated()) {
    Auth.authenticate();
  } else {
    next()
  }
});

export default router;
