import router from "@/router";

export default function findRouteByName(name: string, config: any = {}, routesArg = []) {

  const routes = routesArg.length ? routesArg : router.options.routes;
  let route: any | null = null;

  routes.forEach((_route: any | null) => {
    if (_route.name === name) {
      route = _route;
    }

    if (!route && _route.children) {
      route = findRouteByName(name, config, _route.children);
    }

    return route;
  });

  if (route) {
    route.params = {
      ...route.params,
      ...config.params,
    };
    route.query = {
      ...route.query,
      ...config.query,
    };
  }

  return route;
};