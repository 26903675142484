<template>
    <div class="table-settings box">
        <h2 class="title">{{title}} Table</h2>

        <div class="has-margin-bottom">Check the columns you want visible, drag the columns to reorder them.</div>

        <div class="columns">
            <div class="column">
                <label class="label">Available Columns</label>

                <draggable v-model="localColumns"
                           item-key="field"
                           tag="div"
                           :component-data="{name:'fade'}"
                           @start="drag=true"
                           @end="drag=false"
                           class="target-area"
                >
                  <template #item="{element}">
                    <div class="available-column is-flex has-margin-bottom-small">
                      <div class="handle">
                        <o-icon icon="sort" pack="fas" variant="secondary" size="small" class="sort-icon has-margin-right" />
                      </div>
                      <o-checkbox v-model="element.visible">
                          {{element.label}}
                      </o-checkbox>
                    </div>
                  </template>
                </draggable>

            </div>
        </div>

        <div class="has-text-right">
            <div class="button is-primary" @click="handleSave">
                Save
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">

  import {ref, onMounted, watch, toRefs} from "vue";

  import draggable from 'vuedraggable';
  import Column, {type ColumnType} from "@/use/Column";
  import TableSettings from "@/components/TableSettings.vue";

  let localColumns = ref<ColumnType[]>([]);

  interface TableSettings {
    columns: Column[],
  }

  const props = withDefaults(defineProps<{
    availableColumns: Column[],
    title: string,
  }>(), {
    availableColumns: [],
    title: '',
  });
  const {
    availableColumns,
    title,
  } = toRefs(props);

  const emit = defineEmits<{
    'saved': [TableSettings],
  }>();

  onMounted(() => {
    setLocalColumns();
  });

  function handleSave() {
    const tableSettings: TableSettings = {
      columns: localColumns.value.map((column, i) => {
        return {
          ...column,
          rank: i + 1
        };
      }),
    };

    emit('saved', tableSettings);
  }

  function setLocalColumns() {
    localColumns.value = [
      ...availableColumns.value.map(column => { return { ...column }}).sort((a, b) => {
        if (a.rank > b.rank) return 1;
        else if (a.rank < b.rank) return -1;
        else return 0;
      }),
    ];
  }

  watch(availableColumns, () => {
    setLocalColumns();
  });

</script>

<style lang="scss" scoped>

.table-settings {
  .available-column {
    cursor: move;
    border: #eeeeee 5px solid;
    padding: 2px;
  }
}

</style>